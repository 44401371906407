import React from 'react';
import { Grid, } from "@mui/material";

export const Grid12All = ({children, classes}) => {
  return (
    <Grid item xs={12} sm={12} md={12} xl={12} className={classes}>
        {children}    
    </Grid>
  )
}
Grid12All.defaultProps = {
    classes: "",
}

export const GridA = ({children, classes, size}) => {
  return (
    <Grid item xs={size[0]} sm={size[1]} md={size[2]} xl={size[3]} className={classes}>
        {children}    
    </Grid>
  )
}

GridA.defaultProps = {
  classes: "",
}

export const GridC = ({children, clx}) => {
  return (
    <Grid container className={clx}>
        {children}    
    </Grid>
  )
}

GridC.defaultProps = {
  classes: "",
}
