import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { getApiCall } from "../../common/axios";
import { API_URL } from "../../common/defines";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { useWindowSize, funs } from "./slidercard/SwiperBreakpoints";
import { CustomSlidBox } from "../../globalstyled/homeStyle";
import SlideCard from "./slidercard/OutdoorSliderCard";

function OutdoorSlider() {
  const [firstHalf, setFirstHalf] = useState();
  const [secondHalf, setSecondtHalf] = useState();
  useEffect(() => {
    apiGetChampaingList();
  }, []);

  const favouriteChange = () => {
    apiGetChampaingList()
  }

  const apiGetChampaingList = async (no = 1, limit = 10) => {
    try {
      const queryParams = {
        userId: Cookies.get("userId"),
        pageNo: 1,
        limit: 8,
      };
      const paramsData = { queryParams, headers: {}, isAuthorized: true };
      const res = await getApiCall(API_URL + "campagins/getCampaginList", paramsData);
      let mI = Math.ceil(res.campaginData.length / 2);
      setFirstHalf(res.campaginData.splice(0, mI));
      setSecondtHalf(res.campaginData.splice(-mI));
    } catch (err) {
      console.log("err", err);
    }
  };

  const size = useWindowSize();
  return (
    <CustomSlidBox className="outdoor-swipper-slider">
      <Swiper
        slidesPerView={funs(size.width, "outdoorslider")}
        // spaceBetween={20}
        slidesPerGroup={1}
        loop={true}
        autoplay={{ delay: 100 }}
        loopFillGroupWithBlank={true}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        {[0, 1, 2, 3].map((slider, i) => {
          return (
            <SwiperSlide key={i}>
              <SlideCard firstHalf={firstHalf?.[i]} secondHalf={secondHalf?.[i]} favouriteChange={favouriteChange} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </CustomSlidBox>
  );
}

export default OutdoorSlider;
