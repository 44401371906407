import MarkerClusterer from "@googlemaps/markerclustererplus";

const MyMapComponent = (props) => {
  const lat = props.data.lat || "26.76151185098428";
  const lon = props.data.lon || "83.37599516861914";

  const locations = [{ lat: parseFloat(lat), lng: parseFloat(lon) }];

  const map = new window.google.maps.Map(document.getElementById("map"), {
    center: { lat: parseFloat(lat), lng: parseFloat(lon) },

    zoom: 8,
  });

  // Create an array of alphabetical characters used to label the markers.
  const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  // Add some markers to the map.
  // Note: The code uses the JavaScript Array.prototype.map() method to
  // create an array of markers based on a given "locations" array.
  // The map() method here has nothing to do with the Google Maps API.
  const markers = locations.map((location, i) => {
    return new window.google.maps.Marker({
      position: location,
      label: labels[i % labels.length],
    });
  });

  // Add a marker clusterer to manage the markers.
  new MarkerClusterer(map, markers, {
    imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
  });

  return null;
};

export default MyMapComponent;

MyMapComponent.defaultProps = {
  lat: "0",
  lon: "0",
};
