import React, { useState } from "react";
import { Grid, Modal, Box } from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import SignUp from "./signup/SignUp";
import Otp from "./signup/Otp";
const SignUpModal = () => {
  const [otp, setOtp] = useState(false);
  const signupModal = useSelector((state) => state.loginReducer.signupModal);
  const login = useSelector((state) => state.loginReducer.login);
  const dispatch = useDispatch();
  function handleModalClose() {
    if (signupModal === true) {
      dispatch({ type: "signupmodal" });
    }
  }
  function signInModal() {
    if (signupModal === true) {
      dispatch({ type: "signupmodal" });
      dispatch({ type: "loginmodal" });
    }
  }
  function otpModalHandle() {
    setOtp(!otp);
  }
  function signupHandle() {
    if (login === true) {
      dispatch({ type: "logout" });
    } else {
      dispatch({ type: "login" });
    }
    handleModalClose();
  }
  return (
    <Modal
      open={signupModal}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="signup-box">
        <Grid container className="signup-container">
          <Grid item md={6} lg={6} xl={6} className="left-logo">
            <div className="signup-login-img-wrap">
              <div className="signup-login-img"></div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className="right-field"
          >
            <div className="signup-form-wrap">
              <Grid container justifyContent={"center"}>
                <Grid item xs={11} sm={11} md={10} lg={10} xl={8}>
                  {otp ? (
                    <Otp signupHandle={signupHandle} otpModalHandle={otpModalHandle} />
                  ) : (
                    <SignUp
                      signInModal={signInModal}
                      signupHandle={signupHandle}
                      otpModalHandle={otpModalHandle}
                    />
                  )}
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
export default SignUpModal;
