import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
export const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
    padding:0,
    // paddingLeft: 10,
    '& span': {
      paddingLeft: 0,
      fontSize: 14,
      fontWeight: 'normal'
    },
    '& label':{
      marginLeft:0,
    },
  
  }));

export const GridCustom = styled('div')(({ theme }) => ({
    display:'flex',
    margin: 'auto',
    width: '88%',
    marginTop:'12px',
    [theme.breakpoints.down('md')]: {
      width:'96%',
      margin: '0 auto',
      marginTop:'18px'
    },
  }));  
  export const GridCustomHead = styled('div')(({ theme }) => ({
    display:'flex',
    margin: 'auto',
    width: '88%',
    marginTop:'72px',
    [theme.breakpoints.down('md')]: {
      width:'96%',
      margin: '0 auto',
      marginTop:'78px'
    },
  }));  

  export const GridCustomItem = styled('div')(({ theme }) => ({
    display:'flex',
    margin: 'auto',
    width: '88%',
    marginTop:'72px',
    [theme.breakpoints.down('md')]: {
      width:'100%',
      margin: '0 auto',
      marginTop:'78px',
    },
  }));  