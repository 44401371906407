import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { validationSchema } from "../../common/validate/profile/user";
import { TextField, Button } from "@mui/material";
import { putApiCall } from "../../common/axios";
import { API_URL } from "../../common/defines";
import { useDispatch } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InputAdornment from "@mui/material/InputAdornment";
import CreateIcon from "@mui/icons-material/Create";
import Cookies from "js-cookie";
const UserDetails = ({ name, gmail, number }) => {
  const dispatch = useDispatch();
  const [isNameEdit, setIsNameEdit] = useState(false);
  const [nameChanged, setNameChanged] = useState(name);
  const [numberChanged, setNumberChanged] = useState(number);
  const [nameChangedIsTrue, setNameChangedIsTrue] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: name,
      number: number,
    },
    validationSchema,
    onSubmit: (values) => {
      try {
        const details = {
          userId: Cookies.get("userId"),
          userName: values?.name,
          userMobile: values?.number,
        };
        namedChangedApi(details);
      } catch (err) {}
      formik.resetForm();
    },
  });

  useEffect(() => {
    setNameChanged(name);
  }, [name]);

  const namedChangedApi = async (optionss) => {
    const reqBody = optionss;
    try {
      const paramsData = {
        queryParams: {},
        headers: {},
        isAuthorized: true,
      };
      const response = await putApiCall(API_URL + "profile/editProfile", reqBody, paramsData);
      if (response.status === false) {
        setNameChangedIsTrue(false);
      } else if (response.status === true) {
        dispatch({
          type: "notificationTrigger",
          payload: `${response.message}`,
        });
        setNameChangedIsTrue(false);
        window.location.reload();
      }
    } catch (error) {
      console.log(`Error in Copy cart ${error}`);
    }
  };

  const saveName = (e) => {
    if (e.key === "Enter") {
      if (nameChanged.length >= 6) {
        namedChangedApi();
      } else {
        dispatch({
          typed: "error",
          type: "notificationTrigger",
          payload: `Please Enter Minimum 6 Character`,
        });
      }
    }
  };
  const saveName2 = () => {
    if (nameChanged.length >= 6) {
      namedChangedApi();
    } else {
      dispatch({
        typed: "error",
        type: "notificationTrigger",
        payload: `Please Enter Minimum 6 Character`,
      });
    }
  };

  const renderErrorMessage = (field) => {
    return formik.touched[field] && <span>{formik.errors[field]}</span>;
  };

  return (
    <>
      {/* {nameChangedIsTrue && nameChanged.length >= 6 ? (
        <p style={{ fontSize: "12px", color: "red", fontWeight: "600" }}>
          Press Enter Button to Save the name
        </p>
      ) : null} */}
      <TextField
        disabled={false}
        label="Name"
        // value={nameChanged}
        name="name"
        // onChange={(e) => {
        //   setNameChanged(e.target.value);
        //   if (nameChangedIsTrue === false) {
        //     setNameChangedIsTrue(true);
        //   }
        // }}
        type="text"
        variant="standard"
        // onKeyDown={(e) => {
        //   saveName(e)
        // }}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        {...formik.getFieldProps("name")}
        // InputProps={{
        //   endAdornment: (
        //     <InputAdornment position="end">
        //        {!isNameEdit ? <CreateIcon onClick={(e)=>{setIsNameEdit(true)}} style={{cursor:"pointer" }} /> : (nameChangedIsTrue && nameChanged.length >= 6) ?<CheckCircleIcon onClick={(e)=>{saveName2()}} style={{cursor:"pointer" }} /> : null}
        //     </InputAdornment>
        //   ),
        // }}
      />
      <div className="text-user-err text-err">{renderErrorMessage("name")}</div>
      <TextField disabled label="Email" value={gmail} type="email" variant="standard" />
      <TextField
        label="Mobile Number"
        // value={number}
        // value={numberChanged}
        // onChange={(e)=> setNumberChanged(e.target.value)}
        type="number"
        variant="standard"
        {...formik.getFieldProps("number")}
      />
      <div className="text-user-err text-err">{renderErrorMessage("number")}</div>
      <Button
        onClick={() => {
          formik.handleSubmit();
        }}
        className={`updatepasswordbutton" `}
        variant="contained"
      >
        Update
      </Button>
    </>
  );
};

export default UserDetails;
