import { useState, useEffect } from "react";
export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
};

export const funs = (size, type) => {
  let post = 4;
  if (type === "topslider") {
    if (size > 1500) {
      post = 4;
    } else if (size > 1200) {
      post = 3;
    } else if (size > 900) {
      post = 2;
    } else if (size > 500) {
      post = 2;
    } else {
      post = 1;
    }
  } else if (type === "clientslider") {
    if (size > 1500) {
      post = 3;
    } else if (size > 1200) {
      post = 2;
    } else if (size > 900) {
      post = 2;
    } else if (size > 600) {
      post = 1;
    } else {
      post = 1;
    }
  } else if (type === "outdoorslider") {
    if (size > 1500) {
      post = 4;
    } else if (size > 1200) {
      post = 3;
    } else if (size > 900) {
      post = 2;
    } else if (size > 600) {
      post = 1;
    } else {
      post = 1;
    }
  }
  return post;
};
