import HomeE from "./home/Home";
import OutdoorE from './outdoor/Outdoor'
import ProfileE from "./profile/Profile";
import DashboardE from "./dashboard/Dashboard";
import ProductE from "./Champaign/Champaign";

import AboutE from './common/About';
import TermConditionE from './common/TermCondition';
import PrivacyPolicyE from './common/PrivacyPolicy';
import ContactE from './common/Contact'

import CartE from './Cart/Cart'
import ItemDetailsE from "./itemdetails/ItemDetails";

import BlogE from './blog/index'
import NotFound404E from "./NotFound404";

export const Home = HomeE;
export const Outdoor = OutdoorE;
export const Profile = ProfileE;
export const Dashboard = DashboardE;
export const Product = ProductE;
export const About = AboutE;
export const TermCondition = TermConditionE;
export const PrivacyPolicy = PrivacyPolicyE;
export const Contact = ContactE;
export const Cart = CartE;
export const ItemDetails = ItemDetailsE;
export const Blog = BlogE;
export const NotFound404 = NotFound404E;

