import React from "react";
import { Modal, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
const RequestApprovalModal = ({ openModal, handleModalClose }) => {
  return (
    <Modal open={openModal} onClose={handleModalClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box className="request-modal-wrap">
        <h4 className="title-approve">
          Your campaign has been submitted for Approval.
          <CloseIcon onClick={handleModalClose} className="modal-close-btn" />
        </h4>
        <hr />
        <h4 className="title-approved">What happens now?</h4>
        <p>An Account Manager will get assigned who will help you with</p>
        <ol>
          <li>Campaign Planning</li>
          <li>Checking further discount</li>
          <li>Campaign Execution</li>
        </ol>
        <p>Please expect a mail from the Account Manager within 24hrs.</p>
      </Box>
    </Modal>
  );
};

export default RequestApprovalModal;
