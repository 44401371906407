import React, { useState, useEffect } from "react";
import { AppBar, Toolbar, Box, IconButton, Typography, Badge, MenuItem, Menu, CardMedia, Drawer, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Menu as MenuIcon, Search as SearchIcon, ShoppingBasket, DesktopWindows, AccountCircle, Close } from "@mui/icons-material";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import Cookies from "js-cookie";
import { useHistory, Link } from "react-router-dom";
import "./header.scss";
import { API_URL } from "../../../common/defines";
import { useSelector, useDispatch } from "react-redux";
import logo from "../../../assets/img/header/logo.png";
import Modal from "../../../components/modal/Modal";
import LocalStorage from "../../../services/local_storage";
import { getApiCall } from "../../../common/axios";

let loadStateText = "";

const Header = () => {
  const [searchExpand, setSearchExpand] = useState(false);
  const [expandList, setExpandList] = useState(false);
  const [searchList, setSearchList] = useState();
  const [searchIsLoading, setSearchIsLoading] = useState(false);
  const [cardList, setCardList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [state, setState] = useState({ left: false });
  const dispatch = useDispatch();
  let history = useHistory();
  let localstorage = new LocalStorage();
  const login = useSelector((state) => state.loginReducer.login);
  const loginModal = useSelector((state) => state.loginReducer.loginModal);
  const cart = useSelector((state) => state.cartReducer.isCartReload);

  function loginHandle() {
    handleMenuClose();
    if (loginModal === false) {
      dispatch({ type: "loginmodal" });
    }
  }

  function logoutHandle() {
    handleMenuClose();
    if (login === true) {
      localstorage.logout();
      history.push("/");
      dispatch({ type: "logout" });
      dispatch({ type: "notificationTrigger", payload: `Logout Successful` });
      dispatch({ type: "isloadingload" });
    }
  }

  function searchHandler(e, t) {
    if (t) {
      e.target.value = "";
    }
  }

  async function searchHandle(e) {
    if (e.target.value.length > 0) {
      setExpandList(true);
      const response = await apiGetSearchList(e.target.value);
    } else {
      setExpandList(false);
    }
  }
  const getCartlist = async () => {
    const paramsData = {
      queryParams: { userId: Cookies.get("userId") },
      headers: {},
      isAuthorized: false,
    };
    const response = await getApiCall(API_URL + "cart/myCart", paramsData);
    dispatch({ type: "CARTUPDATE", payload: response?.cartData });
    setCardList(response.cartData);
    return response;
  };

  useEffect(() => {
    getCartlist();
    if (cart === true) {
      dispatch({ type: "cartnotreload" });
    }
  }, [cart]);

  async function apiGetSearchList(query) {
    setSearchIsLoading(true);
    try {
      const paramsData = {
        queryParams: {
          searchQuery: query,
          searchType: "keywords",
          pageNo: 1,
          limit: "10",
        },
        headers: {},
        isAuthorized: true,
      };
      const res = await getApiCall(API_URL + "search/searchData/", paramsData);
      if (res?.message?.toLowerCase() === "data found.") {
        setSearchIsLoading(false);
        setSearchList(res?.searchData);
        return res;
      } else {
        setSearchIsLoading(false);
        setSearchList(res?.searchData);
        return res.message;
      }
    } catch (err) {
      setSearchIsLoading(false);
      console.log("err", err);
    }
  }

  function handleCart() {
    history.push("/bag");
  }
  function profileClickHandler() {
    handleMenuClose();
    history.push("/profile");
  }

  function dashboardClickHandler() {
    handleMenuClose();
    history.push("/dashboard");
  }

  if (searchList?.length > 0) {
    loadStateText = "Search Result";
  } else {
    loadStateText = "No result";
  }
  if (searchIsLoading) {
    loadStateText = "loading...";
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = "primary-search-account-menu";

  const menulogin = (
    <span>
      <MenuItem onClick={profileClickHandler}>Profile</MenuItem>
      <MenuItem onClick={dashboardClickHandler}>Dashboard</MenuItem>
      <MenuItem onClick={logoutHandle}>Logout</MenuItem>
    </span>
  );
  const menulogout = <MenuItem onClick={loginHandle}>Login</MenuItem>;
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {!login ? menulogout : menulogin}
    </Menu>
  );

  return (
    <Box>
      <AppBar position="fixed">
        <Toolbar className="toolbarwrapper">
          <div className="logowrapper">
            <IconButton size="large" edge="start" color="inherit" aria-label="open drawer" className="iconwrapper" onClick={toggleDrawer("left", true)}>
              <MenuIcon />
            </IconButton>
            <Link className="logo-imag-ishtihar" to="/">
              <CardMedia className="headerlogo" component="img" image={logo} alt="ishtihar" />
              <p className="logo-title-ishtihar">Ishtihar</p>
            </Link>
          </div>
          <div className="searchwrapper">
            <div className={`searchbase ${searchExpand && "search-box-wrapper-expand"}`}>
              <div className={`iconwarpper iconwarpper-wrapper-expand `}>
                <SearchIcon onClick={() => setSearchExpand(true)} />
              </div>
              <div className={`search-container search-container-wrapper-expand`}>
                <input
                  type="text"
                  className="searchinput"
                  onChange={searchHandle}
                  onBlur={(e) => {
                    setTimeout(() => {
                      setExpandList(false);
                      searchHandler(e, true);
                    }, 200);
                  }}
                />
              </div>
              <div className={`close-container close-container-wrapper-expand`}>
                <Close onClick={() => setSearchExpand(false)} />
              </div>
              <ul className={`search-list list-container-wrapper-expand ${expandList && "expanded"}`}>
                <p>{loadStateText}</p>

                {!searchIsLoading &&
                  searchList?.map((list, i) => {
                    return (
                      <li key={i}>
                        <a
                          onClick={() => {
                            history.push("/outdoor/" + list.campaignName + "?id=" + list.campaignId);
                            window.location.reload();
                          }}
                        >
                          <span className="list-overlay-text">
                            {list.mediaOption} - {list.locality}
                          </span>
                          <span>Outdoor</span>
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </div>
            <IconButton size="large" aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={cardList?.length || null} color="error">
                <ShoppingBasket onClick={handleCart} />
              </Badge>
            </IconButton>
            <IconButton size="large" edge="end" aria-label="account of current user" aria-controls={menuId} aria-haspopup="true" onClick={handleProfileMenuOpen} color="inherit">
              {!login ? <AccountCircle /> : <img className="avatar-header-logo" src={`${localstorage.getItem("avatarImg")}`} />}
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      {renderMenu}

      {/* Drawer Section */}
      <Drawer anchor={"left"} open={state["left"]} onClose={toggleDrawer("left", false)}>
        <Box className="drawerwrapper" role="presentation" onClick={toggleDrawer("left", false)} onKeyDown={toggleDrawer("left", false)}>
          <Typography variant="body2" component="div" className="drawerlinkname">
            Ishtihar
          </Typography>
          <List>
            <Link className="list-item-link" to="/outdoor">
              <ListItem button>
                <ListItemIcon>
                  <DesktopWindows className="drawericon" />
                </ListItemIcon>
                <ListItemText className="item-link" primary={"Outdoor"} />
              </ListItem>
            </Link>
            <Link className="list-item-link" to="/" onClick={()=>{window.open("http://admin.ishtihar.in/", "_blank")}}>
              <ListItem button>
                <ListItemIcon>
                  <SupervisorAccountIcon className="drawericon" />
                </ListItemIcon>
                <ListItemText className="item-link" primary={"Sub Admin"} />
              </ListItem>
            </Link>
          </List>
        </Box>
      </Drawer>
      <Modal />

    </Box>
  );
};

export default Header;
