import React, { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { API_URL } from "../../../../common/defines";
import { Button, Stack, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useFormik } from "formik";
import { fetchProfileSuccess } from "../../../../state/actions/UserActions";
import LocalStorage from "../../../../services/local_storage";
import { useDispatch } from "react-redux";
import { postApiCall, getApiCall } from "../../../../common/axios";

import * as yup from "yup";
const SignUp = ({ otpModalHandle, signupHandle }) => {
  const [otpno, setOtpNo] = useState("");
  let localstorage = new LocalStorage();
  const [isOtpAllow, setIsOtpAllow] = useState(false);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: yup.object().shape({
      email: yup.string().required("Email is required").email("Email is invalid"),
    }),
    onSubmit: (values) => {
      try {
        const otpDetails = {
          registerType: "register_with_otp",
          userEmail: values?.email,
        };
        apiMailForOtp(otpDetails);
      } catch (err) {}
    },
  });

  const renderErrorMessage = (field) => {
    return formik.touched[field] && <div className="text-error text-err">{formik.errors[field]}</div>;
  };

  const apiMailForOtp = async (reqBody) => {
    const paramsData = {
      queryParams: {},
      headers: {},
      isAuthorized: true,
    };
    try {
      const response = await postApiCall(API_URL + "authentication/register", reqBody, paramsData);
      if (response.status === false) {
        dispatch({
          type: "notificationTrigger",
          typed: "warning",
          payload: `${response.message}`,
        });
      } else if (response.status === true) {
        dispatch({
          type: "notificationTrigger",
          payload: `${response.message}, Please check mail and enter the OTP`,
        });
        setIsOtpAllow(true);
      }
    } catch (error) {
      alert(error);
    }
  };

  const apiOtpVerify = async () => {
    const paramsData = {
      queryParams: {},
      headers: {},
      isAuthorized: true,
    };
    const reqBody = {
      otp: otpno,
      username: formik.values.email,
      otpFor: "register_with_email",
    };
    try {
      const response = await postApiCall(API_URL + "verification/verifyOtp", reqBody, paramsData);
      if (response.status === false) {
        dispatch({
          type: "notificationTrigger",
          typed: "error",
          payload: `${response.message}`,
        });
      } else if (response.status === true) {
        localstorage.login(true, response.data.login_token, response.data.userId);
        dispatch({ type: "login" });
        dispatch({ type: "isloadingload" });
        signupHandle();
        apiGetProfile();
      }
    } catch (error) {
      alert(error);
    }
  };

  const apiGetProfile = async () => {
    try {
      const paramsData = {
        queryParams: {},
        headers: {},
        isAuthorized: true,
      };
      const res = await getApiCall(API_URL + "profile/profileDetail/" + Cookies.get("userId"), paramsData);
      localstorage.setItem("avatarImg", res.data.userImage);
      dispatch({
        type: "notificationTrigger",
        payload: `Hi, ${res.data.userName}`,
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const apiSetProfile = (token) => {
    const headers = {
      Authorization: token,
    };
    axios
      .get(`${API_URL}profile/profileDetail/${Cookies.get("userId")}`, {
        headers,
      })
      .then((response) => {
        dispatch(fetchProfileSuccess(response.data.data));
        if (response.data.status === false) {
        } else if (response.data.status === true) {
        }
      })
      .catch((error) => {});
  };

  return (
    <div className="login-field-container">
      <Stack className="stack-wrapper back" spacing={6}>
        <ArrowBackIcon onClick={otpModalHandle} />
      </Stack>
      <div>
        <p className="forgot-txt">Please enter your email and OTP to verify your account.</p>
        <p className="forgot-txt">
          <span>(*Please check your Inbox/Spam folder for the OTP)</span>
        </p>
        <TextField
          label="Email *"
          variant="standard"
          className={"sl-field"}
          id="email"
          name="email"
          {...formik.getFieldProps("email")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <Button
                  className="get-otp-btn"
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                >
                  Get OTP
                </Button>
              </InputAdornment>
            ),
          }}
        />
        {renderErrorMessage("email")}
        {isOtpAllow ? (
          <TextField
            label="OTP *"
            type="number"
            variant="standard"
            className={"sl-field"}
            id="otp"
            name="otp"
            value={otpno}
            onChange={(e) => {
              setOtpNo(e.target.value);
            }}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 4);
            }}
          />
        ) : (
          <br />
        )}
      </div>
      {isOtpAllow ? (
        <div className="btn-trig-container">
          <Button disabled={!isOtpAllow} variant="contained" onClick={apiOtpVerify}>
            Verify
          </Button>
        </div>
      ) : (
        <br />
      )}
    </div>
  );
};

export default SignUp;
