import React, { useState, useEffect, useRef } from "react";
import { Grid, Box, Skeleton } from "@mui/material";
import Cookies from "js-cookie";
import CreateIcon from "@mui/icons-material/Create";
import { API_URL } from "../../common/defines";
import { useDispatch } from "react-redux";
import { postApiCall, getApiCall, putApiCall } from "../../common/axios";
import LocalStorage from "../../services/local_storage";

const Preview = ({ image, id }) => {
  const localstorage = new LocalStorage();
  const dispatch = useDispatch();
  const [previewImage, setPreviewImage] = useState(image);
  const [imageLoading, setImageLoading] = useState(false);

  const avatarPreview = (e) => {
    setPreviewImage(URL.createObjectURL(e.target.files[0]));
    uploadFileApi(e.target.files[0]);
    setImageLoading(true);
  };

  const avatarInput = useRef(null);
  function handleClick() {
    avatarInput.current.click();
  }

  useEffect(() => {
    setPreviewImage(image);
  }, [image]);

  const uploadFileApi = async (fil) => {
    const formData = new FormData();
    formData.append("fileFor", "user");
    formData.append("fileToUpload", fil);
    const headers = {
      "Content-Type": "multipart/form-data",
    };

    const paramsData = {
      queryParams: {},
      headers,
      isAuthorized: true,
    };
    try {
      const response = await postApiCall(API_URL + "upload/uploadFiles", formData, paramsData);
      if (response.status === false) {
      } else if (response.status === true) {
        let image = response.data.file_path.split("/");
        userProfileDetails(image[image.length - 1]);
      }
    } catch (error) {
      console.log(`Error in Copy cart ${error}`);
    }
  };

  const userProfileDetails = async (image) => {
    try {
      const paramsData = {
        queryParams: {},
        headers: {},
        isAuthorized: true,
      };
      const res = await getApiCall(API_URL + "profile/profileDetail/" + Cookies.get("userId"), paramsData);
      imageChangeApi({ ...res.data, userImage: image });
    } catch (err) {
      console.log("err", err);
    }
  };

  const imageChangeApi = async (reqBody) => {
    try {
      const paramsData = {
        queryParams: {},
        headers: {},
        isAuthorized: true,
      };
      const response = await putApiCall(API_URL + "profile/editProfile", reqBody, paramsData);
      if (response.status === false) {
      } else if (response.status === true) {
        dispatch({
          type: "notificationTrigger",
          payload: `${response.message}`,
        });
        localstorage.setItem("avatarImg", response.data.userImage);
        dispatch({ type: "cartreload" });
        setImageLoading(false);
      }
    } catch (error) {
      console.log(`Error in Copy cart ${error}`);
    }
  };

  return (
    <Grid align="right" item xs={12} sm={6}>
      <Grid container spacing={2} className="avatardiv">
        <Grid className="avatarWrapper" item xs={12} sm={12} md={6}>
          <input onChange={avatarPreview} id="avatar-picker" hidden type="file" multiple accept="image/*" ref={avatarInput} />
          <Box className="avatarimgwrapper">
            {imageLoading || image?.length === 0 ? (
              <Skeleton variant="circular" width={250} height={250} />
            ) : (
              <>
                <CreateIcon className="avatar-edit-icon" onClick={handleClick} />
                <img className="avatarimg" alt="Remy Sharp" src={previewImage} />
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Preview;
