import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import "./sharedbutton.scss";
const SharedButton = () => 
    <div className="sharedContainer">
      <div onClick={()=>{
        window.open("https://api.whatsapp.com/send?phone=8171642992&text="+window.location.href)
      }}>
        <div className="sharedBox">
          <WhatsAppIcon className="icond" />
        </div>
      </div>
    </div>

export default SharedButton;
