import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { Grid, IconButton } from "@mui/material";
import { goTop } from "../../components/common/gFun/GFun";
import { FmdGood as FmdGoodIcon } from "@mui/icons-material";
import "./champaign.scss";
import Content from "../../components/champaign/Content";
import { BreadcrumbsBox, LinK } from "../../components/shared/BreadCrumbs";
import { Grid12All, GridA } from "../../components/shared/GridWrap";
import CamMapModal from "../../components/modal/campaign/CamMapModal";

const Champaign = () => {
  const dispatch = useDispatch();
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const cName = useSelector((state) => state.CampaignReducer.campaignName);
  const par = useParams().slug;
  Cookies.set("orderId", par);

  useEffect(() => {
    goTop();
    return () => {
      dispatch({ type: "cdcleanup" });
      Cookies.remove("countedId");
      window.location.reload();
    };
  }, []);

  return (
    <div className="champaign-wrapper">
      <Grid spacing={1} container>
        <GridA size={[10, 10, 8, 8]}>
          <BreadcrumbsBox>
            <LinK title={"Home"} classes="breakcrumb-link" link="/" />
            <LinK title={"Dashboard"} classes="breakcrumb-link" link="/dashboard" />
            <LinK title={cName} classes="breakcrumb-link active" link="/" />
          </BreadcrumbsBox>
        </GridA>
        <Grid item xs={2} sm={2} md={4} xl={4} align="right">
          {/****** map components */}
          {/* <IconButton className="head-icon-button" aria-label="delete" size="small" onClick={handleShow}>
            <FmdGoodIcon className="head-icon" />
          </IconButton> */}
        </Grid>
        <Grid12All>
          <Content />
        </Grid12All>
      </Grid>

      {/* ############### Modal Map  ############## */}

      <CamMapModal cName={cName} show={show} handleClose={handleClose} />
    </div>
  );
};

export default Champaign;
