import React from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import MyMapComponent from "./MyMapComponent";

const render = (status) => {
  if (status === Status.LOADING) return "Loading...";
  if (status === Status.FAILURE) return "Error";
  return null;
};

const MapWrapper = () => {
  return (
    <div className="mapStyle">
      <Wrapper apiKey={""} render={render}>
        <MyMapComponent />
      </Wrapper>
    </div>
  );
};

export default MapWrapper;
