import { excludeById, getTodayStr } from "./utils";
import { API_URL } from "../../common/defines";
import { getApiCall } from "../../common/axios";
import Cookies from "js-cookie";
import { store } from "../store";
import moment from "moment";
/*
functions that simulate network requests
*/

let todayStr = getTodayStr();
let eventGuid = 100;
let eventDb = [
  {
    id: createEventId(),
    title: "All-day event",
    start: todayStr,
  },
  {
    id: createEventId(),
    title: "Timed event",
    start: todayStr + "T12:00:00",
  },
];

let bookedDB = [
  {
    id: 201,
    title: "Booked",
    start:  new Date(2022,6,10),
  },
  {
    id: 202,
    title: "Booked",
    start: new Date(2022,6,19),
  },
];

const formatDate = (date) => {
  let d = new Date(date);
  let month = (d.getMonth() + 1).toString();
  let day = d.getDate().toString();
  let year = d.getFullYear();
  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }
  return [year, month, day].join('-');
}

const daysOfYear = [];
const getListBookedDate = async() => {
  var end = bookedDB[1].start;
  var idx = 840;
  // console.log("ending",end)
  for (
    var start = bookedDB[0].start;
    start <= end;
    start.setDate(start.getDate() + 1)
  ) {
    idx += 1;
    daysOfYear.push({id:idx,title: "Booked",start:formatDate(start),color:"#ff0000b5"});
    // console.log("end -date",formatDate(start))
  }

};


const getApiDetails = async () => {
  let countT = 0;
  const paramsData = {
    queryParams: { orderId: Cookies.get("orderId") },
    headers: {},
    isAuthorized: true,
  };
  const response = await getApiCall(
    API_URL + "dashboard/getOrderDetails",
    paramsData
  );

  var enumerateDaysBetweenDates = function(startDate, endDate) {
    var dates = [];
    var currDate = moment(startDate).startOf('day');
    var lastDate = moment(endDate).startOf('day');
    while(currDate.add(1, 'days').diff(lastDate) < 0) {
      // console.log("it is mement - - -- >",moment(currDate.toDate()).format("YYYY-MM-DD"));
      dates.push(moment(currDate.clone().toDate()).format("YYYY-MM-DD"));
    }
    return dates;
  };

  let dates = enumerateDaysBetweenDates(response?.orderData?.dateDetails[0]?.startDate,response?.orderData?.dateDetails[0]?.endDate)
  dates = [response?.orderData?.dateDetails[0]?.startDate,...dates,response?.orderData?.dateDetails[0]?.endDate]
  // console.log('hi this -skldjfl tie', dates)
  // console.log("hi therer", response);
  bookedDB[0].start = new Date(response?.orderData?.orderItems[0]?.startDate)
  bookedDB[1].start = new Date(response?.orderData?.orderItems[0]?.endDate)
  // console.log("hi therer", bookedDB[1].start);
  await getListBookedDate();
  const alterDate = dates.map((data, i) => {
    // countT += parseInt(data.total_quantity);
    return { ...data, title: 1, id: i, start: data, };
    // return{...data,title: data.total_quantity,start:data.date,id:i,start:data.date}
  });

  // console.log('date updates  - - -',alterDate)
 
  // let alterDater = [...alterDate,{date: "2022-06-26",
  // id: 112,
  // start: "2022-06-26",
  // title: "1",}]
  // console.log('alerterer - - ->',alterDater)
  // countT += 1;
  // if(Cookies.get("countedId")){

  // } else{
  //   Cookies.set("countedId",true)
  const from = true
  // console.log('payload check - - -- ',from,countT)
    store.dispatch({
      type: "updatenumberofcount",
      payload: {from,countT},
    });
  // }
  return alterDate;
};

const DELAY = 200;
let simulateErrors = false;

document.addEventListener("keypress", (ev) => {
  // if (ev.key === 'e') {
  //   alert('You pressed the key "e". Will begin to simulate errors.')
  //   simulateErrors = true
  // }
});

export async function requestEventsInRange(startStr, endStr) {
  const list = await getApiDetails();

  // console.log(list,'---------list -------------');

  // console.log(`[STUB] requesting events from ${startStr} to ${daysOfYear}`);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (simulateErrors) {
        reject(new Error("error"));
      } else {
        resolve([...list,...daysOfYear]); // won't use the start/end, always return whole DB
      }
    }, DELAY);
  });
}

export function requestEventCreate(plainEventObject) {
  // console.log("[STUB] requesting event create:", plainEventObject);
  store.dispatch({
    type: "orderupdate",
    plainEventObject,
  });

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (simulateErrors) {
        reject(new Error("error"));
      } else {
        let newEventId = parseInt(createEventId());
        let objWithId = { ...plainEventObject, id: createEventId() };
        let quantity = parseInt(objWithId.title);
        // console.log("objWithId.title", objWithId.title);
        store.dispatch({
          type: "updatenumberofcount",
          payload: quantity,
        });
        // console.log('promise --->',typeof(parseInt(quantity)))
        // objWithId.title = `${quantity * select(store.getState())}`
        // console.log('promise --->',select(store.getState()))
        // console.log('promise - objWithId -->',objWithId)

        eventDb.push(objWithId);
        resolve(newEventId);
      }
    }, DELAY);
  });
}

export function requestEventUpdate(plainEventObject) {
  // console.log("[STUB] requesting event update:", plainEventObject);

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (simulateErrors) {
        reject(new Error("problem"));
      } else {
        eventDb = excludeById(eventDb, plainEventObject.id);
        eventDb.push(plainEventObject);
        resolve(eventDb);
      }
    }, DELAY);
  });
}

export function requestEventDelete(eventId) {
  // console.log("[STUB] requesting event delete, id:", eventId);

  // store.dispatch({
  //   type: 'orderremove',
  //   eventId
  // })

  // setTimeout(()=>{
  //   store.dispatch({
  //     type: 'orderremove',
  //   })
  // },1000)

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      // simulate network delay
      if (simulateErrors) {
        reject(new Error("problem"));
      } else {
        eventDb = excludeById(eventDb, eventId);
        resolve(eventDb);
      }
    }, DELAY);
  });
}

function createEventId() {
  return String(eventGuid++);
}
