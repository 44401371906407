import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { Grid, Button } from "@mui/material";
import { API_URL } from "../../common/defines";
import { getApiCall } from "../../common/axios";
import { goTop } from "../../components/common/gFun/GFun";
import { GridCustom } from "../../globalstyled/homeStyle";
import "./profile.scss";
import Preview from "../../components/profile/Preview";
import UserDetails from "../../components/profile/UserDetails";
import UserPassword from "../../components/profile/UserPassword";

function Profile() {
  const [data, setData] = useState([]);
  const [showInput, setShowInput] = useState(false);
  useEffect(() => {
    goTop();
    apiGetProfile();
  }, []);

  function changeButtonHandler() {
    setShowInput(!showInput);
  }
  const apiGetProfile = async () => {
    try {
      const paramsData = {
        queryParams: {},
        headers: {},
        isAuthorized: true,
      };
      const res = await getApiCall(API_URL + "profile/profileDetail/" + Cookies.get("userId"), paramsData);
      setData(res.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <>
      <GridCustom>
        <Grid container spacing={2} className={"profileContainer"}>
          <Preview image={data?.userImage} id={data?.userId} />
          <Grid item xs={12} sm={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <form>
                  <div className="profileinputwrapper">
                    {data?.userName && !showInput && <UserDetails name={data?.userName || ""} gmail={data?.userEmail || ""} number={data?.userMobile || " "} />}

                    {!showInput && (
                      <>
                        <br />
                        <Button onClick={changeButtonHandler} className="changepasswordbutton" variant="contained">
                          Change Password
                        </Button>
                      </>
                    )}
                    {showInput && <UserPassword changeButtonHandler={changeButtonHandler} />}
                  </div>
                </form>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </GridCustom>
    </>
  );
}

export default Profile;
