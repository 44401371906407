import React from 'react'
import { GridA } from '../shared/GridWrap';
import {Link} from 'react-router-dom';

const Details = () => {
  
  const pushHandler=()=>{
    setTimeout(()=>{
        window.location.reload()
    },100)
}
  return (
    <GridA size={[12,12,12,12]} classes="outdoor-details-wrap">
      <h4 className="details-title">Outdoor Advertising Agency</h4>
      <p className="details-para">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.</p>
      <h4 className="details-title">Why opt for Outdoor Advertising?</h4>
      <p className="details-para">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.Contrary to popular belief, Lorem Ipsum is not simply random text.</p>
      <h4 className="details-title">Advantages of Outdoor Advertising</h4>
      <ul className="ol-list-wrap">
        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
      </ul>
      <h4 className="details-title">Outdoor Advertising Options</h4>
      <ol className="ol-list-wrap">
        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
        
        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
        
      </ol>
      <h4 className="details-title">Ishtihar- Outdoor Advertising Agency</h4>
      <p className="details-para">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary</p>
      <h4 className="details-title">Pan India Outdoor Advertising Rates 2022</h4>
      <p className="details-para">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary,</p>
      <h4 className="details-title">Top 5 Pan India Outdoor Advertising Campaigns From Oct - Dec 2021</h4>
      <ol className="ol-list-wrap">
      <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
        
        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</li>
      </ol>
      <h5 className="details-title">Get the list of top Outdoor Advertising Agencies in the cities of India. </h5>
      <ol className="ol-list-wrap">
        <li><Link onClick={pushHandler} to="/outdoor?location=uttar pradesh&city=gorakhpur">Outdoor Advertising Agency in Gorakhpur</Link></li>
        <li><Link onClick={pushHandler} to="/outdoor?location=maharashtra&city=mumbai city">Outdoor Advertising Agency in Mumbai</Link></li>
        <li><Link onClick={pushHandler} to="/outdoor?location=delhi">Outdoor Advertising Agency in Delhi</Link></li>
        <li><Link onClick={pushHandler} to="/outdoor?location=west bengal&city=kolkata">Outdoor Advertising Agency in Kolkata</Link></li>
        <li><Link onClick={pushHandler} to="/outdoor?location=karnataka&city=bangalore">Outdoor Advertising Agency in Bangalore</Link></li>
        <li><Link onClick={pushHandler} to="/outdoor?location=maharashtra&city=pune">Outdoor Advertising Agency in Pune</Link></li>
      </ol>
    </GridA>
  )
}

export default Details