import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  TableHead,
  TableSortLabel,
  TableCell,
  TableRow,
  Checkbox,
} from "@mui/material";

import { maptablehead } from "../../data";

export default function MapTableHead({
  onSelectAllClick,
  numSelected,
  rowCount,
}) {
  const [headCells, setHeadCells] = useState(maptablehead);

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            size="small"
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells?.map((headCell) => (
          <TableCell key={headCell.id} className="head-cell">
            <TableSortLabel>{headCell.label}</TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

MapTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};
