import React from "react";
import { GoogleLogin } from "react-google-login";
import { postApiCall, getApiCall } from "../../../../common/axios";
import { API_URL } from "../../../../common/defines";
import LocalStorage from "../../../../services/local_storage";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
// const responseGoogle = (response) => {
//   console.log(response);
// };

const GoogleBtn = ({ loginHandle, type, title }) => {
  const dispatch = useDispatch();
  let localstorage = new LocalStorage();
  const onSuccess = (res) => {
    // console.log("Login Success: currentUser:", res);
    // console.log("Login Success: id:", res.googleId);
    // console.log("Login Success: token:", res.tokenId);
    // alert(
    //   `Logged in successfully welcome ${res.profileObj.name} 😍. \n See console for full profile object.`
    // );
    refreshTokenSetup(res);
  };

  const onFailure = (res) => {
    // console.log(res)
    if (res?.error === "popup_closed_by_user") {
      alert("Please try again.");
      loginHandle();
    }
    // alert(
    //   `Failed to login. 😢 Please ping this to repo owner twitter.com/sivanesh_fiz`
    // );
  };

  const refreshTokenSetup = async (res) => {
    const { email, googleId, name } = res.profileObj;
    const { accessToken } = res;
    const reqBody = {
      socialId: googleId,
      socialToken: accessToken,
      provider: "google",

      loginType: "social",
      userName: name,
      userEmail: email,
      userImage: "",
    };
    try {
      const response = await postApiCall(API_URL + "authentication/socialLogin", reqBody);
      if (response.status === false) {
      } else if (response.status === true) {
        localstorage.login(true, response.data.login_token, response.data.userId);
        // localstorage.setItem("userId", response.data.data.userId);
        dispatch({ type: "login" });
        dispatch({ type: "isloadingload" });
        // console.log("login signup response  ---> ", response);
        loginHandle();
        apiGetProfile();
        // apiSetProfile(response.data.data.login_token);
      }
    } catch (error) {
      console.log(`Error in Copy cart ${error}`);
    }

    async function apiGetProfile() {
      try {
        const paramsData = {
          queryParams: {},
          headers: {},
          isAuthorized: true,
        };
        const res = await getApiCall(API_URL + "profile/profileDetail/" + Cookies.get("userId"), paramsData);

        dispatch({
          type: "profile_details",
          payload: res.data,
        });
        localstorage.setItem("avatarImg", res.data.userImage);
        dispatch({
          type: "notificationTrigger",
          payload: `Hi, ${res.data.userName}`,
        });
      } catch (err) {
        console.log("err", err);
      }
    }

    // Timing to renew access token
    let refreshTiming = (res.tokenObj.expires_in || 3600 - 5 * 60) * 1000;

    const refreshToken = async () => {
      const newAuthRes = await res.reloadAuthResponse();
      refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000;
      // console.log('newAuthRes:', newAuthRes);
      // saveUserToken(newAuthRes.access_token);  <-- save new token
      // localStorage.setItem('authToken', newAuthRes.id_token);

      // Setup the other timer after the first one
      setTimeout(refreshToken, refreshTiming);
    };

    // Setup first refresh timer
    setTimeout(refreshToken, refreshTiming);
  };

  return (
    <div className="google-btn">
      <GoogleLogin
        clientId="292265705282-f312mdlenbj4hqlvf63a87m89rkl6bgg.apps.googleusercontent.com"
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={"single_host_origin"}
        buttonText={title.toUpperCase()}
      />
    </div>
  );
};

export default GoogleBtn;
