const initialState = {
    data: {
      userName:'',
      userEmail:'',
      userImage:'',
    },
  };

  function UserReducer(state = initialState, action) {
    switch (action.type) {
      case "profile_details":
        // console.log('action user Reducer payload',action.payload)
        return {
          ...state,
          data: action.data
        };
      
      default:
        return state;
    }
  }
  
  export default  UserReducer;
  