import React, { useState, useEffect } from "react";
import { API_URL } from "../../common/defines";
import { getApiCall } from "../../common/axios";
import Cookies from "js-cookie";
import { Grid, Box, Button } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { goTop } from "../../components/common/gFun/GFun";
import { GridCustomHead } from "../../globalstyled/outdoor";
import "./outdoor.scss";
import FinalMap from "../../components/map/FinalMap";
import OutdoorItemCard from "../../components/outdoor/OutdoorItemCard";
import FilterList from "../../components/outdoor/FilterList";
import Details from "../../components/common/Details";
import Loader from "../../components/common/Loader";
import { useLocation } from "react-router-dom";
import { BreadcrumbsBox, LinK } from "../../components/shared/BreadCrumbs";
import { ViewLM } from "../../components/outdoor/OutdoorComponents";
import { Grid12All } from "../../components/shared/GridWrap";

let qParFiltr = {
  pageNo: 1,
  limit: 8,
};

const Index = () => {
  const dispatch = useDispatch();
  const [islimitReact, setIslimitReact] = useState({
    limit:false,
  });
  const [yesFiltered, setYesFiltered] = useState(false);
  const search = useLocation().search;
  const name = new URLSearchParams(search).get("location");
  const cityf = new URLSearchParams(search).get("city");
  const [filteDataNotFound, setFilterDataNotFound] = useState(true);
  const [champaignList, setChampaignList] = useState();
  const [isLoading, setIsLoading] = useState();
  const [isFilterLoading, setIsFilterLoading] = useState(false);
  const [view, setView] = useState(true);
  const [filterList, setFilterList] = useState();
  const [qParamsList, setQParamsList] = useState({
    pageNo: 1,
    limit: 8,
    firstTimeLoad: true,
  });
  function handleView() {
    setView(!view);
  }
  useEffect(() => {
    goTop();
    apiFilterList();
  }, []);

  async function getFilterbyUrlAsyncFunc() {
    if (name?.length > 0) {
      for (let i = 0; i < filterList?.location?.length; i++) {
        if (
          filterList?.location[i]?.name?.toLowerCase() === name.toLowerCase()
        ) {
          setFilterDataNotFound(false);
          const citylist = await cityHandleApi(i + 1);
          // let a = citylist.filter((city)=>city.cityName.toLowerCase() ===  cityf.toLowerCase())
          let a = citylist.filter((city) =>
            city.cityName.toLowerCase().includes(cityf?.toLowerCase())
          );
          qParFiltr = {
            location: i + 1,
            cityId: a[0]?.cityId || "",
            ...qParFiltr,
          };
          getChampaingListFilterApi(qParFiltr);
          setYesFiltered(true);
        } else {
        }
      }
    }
  }

  useEffect(() => {
    getFilterbyUrlAsyncFunc();
  }, [filterList]);

  const cityHandleApi = async (stateId) => {
    try {
      const queryParams = {
        stateId: stateId,
      };
      const paramsData = { queryParams, headers: {}, isAuthorized: true };
      const res = await getApiCall(
        API_URL + "search/getCityByStateId",
        paramsData
      );
      return res.cityData;
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    if (!name && !cityf) {
      apiGetChampaingList();
      setFilterDataNotFound(false);
    }
  }, [qParamsList.limit]);

  const extendData = () => {
    setQParamsList((prevState) => {
      return {
        ...prevState,
        limit: prevState.limit + 8,
        firstTimeLoad: false,
      };
    });
  };

  const extendDataFilter = () => {
    qParFiltr = { ...qParFiltr, limit: qParFiltr.limit + 8 };
    getChampaingListFilterApi(qParFiltr);
  };

  const apiGetChampaingList = async () => {
    qParamsList.firstTimeLoad === true && setIsLoading(true);
    try {
      const queryParams = {
        userId: Cookies.get("userId"),
        pageNo: qParamsList.pageNo,
        limit: qParamsList.limit,
      };
      const paramsData = { queryParams, headers: {}, isAuthorized: true };
      const res = await getApiCall(
        API_URL + "campagins/getCampaginList",
        paramsData
      );
      qParamsList.firstTimeLoad === true && setIsLoading(false);
      if(res.campaginData.length === qParamsList.limit){
        setIslimitReact((prev)=>{
          return {
            ...prev,
            limit:false,
          }
        })
      } else {
        setIslimitReact((prev)=>{
          return {
            ...prev,
            limit:true,
          }
        })
      }
      console.log("CompaignList--->>>", res)
      setChampaignList(res.campaginData);
    } catch (err) {
      console.log("err", err);
    }
  };

  const apiFilterList = async () => {
    try {
      const queryParams = {};
      const paramsData = { queryParams, headers: {}, isAuthorized: true };
      const res = await getApiCall(API_URL + "search/filterList", paramsData);
      setFilterList(res.filterList);
    } catch (err) {
      console.log("err", err);
    }
  };

  const getChampaingListFilterApi = async (options) => {
    try {
      const queryParams = {
        ...options,
      };
      const paramsData = { queryParams, headers: {}, isAuthorized: true };
      const res = await getApiCall(API_URL + "search/filterData", paramsData);
      if (res.filterData.length <= 0) {
        dispatch({
          type: "notificationTrigger",
          typed: "error",
          payload: `${res.message}`,
        });
      }
      
      setChampaignList(res.filterData);
    
      setIsFilterLoading(false);
      if(res.filterData.length === qParFiltr.limit){
        setIslimitReact((prev)=>{
          return {
            ...prev,
            limit:false,
          }
        })
      } else {
        setIslimitReact((prev)=>{
          return {
            ...prev,
            limit:true,
          }
        })
      }
      
    } catch (err) {
      console.log("err", err);

      // dispatch({
      //   type: "notificationTrigger",
      //   typed: "error",
      //   payload: `Connection problems.`,
      // });

      setIsFilterLoading(false);
    }
  };

  const updateFilter = (options) => {
    qParFiltr = { ...qParFiltr, ...options, limit: 8 };
    
    setIslimitReact((prev)=>{
      return {
        ...prev,
        limit:false,
      }
    })
    
    getChampaingListFilterApi(qParFiltr);
    setIsFilterLoading(true);
  };
  
  const yesFilteredFunc = (selct) => {
    setYesFiltered(selct);
  };

  const favouriteChange  = () => {
    console.log("favouriteStatus-->", filterList)
    apiGetChampaingList()
  }
  
  return (
    <>
      <Box>
        <GridCustomHead>
          <Grid spacing={2} container>
            <Grid12All>
              <BreadcrumbsBox>
                <LinK title="Home" classes="breakcrumb-link" link="/" />
                <LinK title="Outdoor" classes="breakcrumb-link active" link="/outdoor" />
              </BreadcrumbsBox>
            </Grid12All>
            {isLoading ? (
              <Grid12All>
                <Loader />
              </Grid12All>
            ) : (
              <>
                {/** Button for Map and List View */}
                <ViewLM handleView={handleView} view={view}/>
                <FilterList
                  updateFilter={updateFilter}
                  filterList={filterList}
                  yesFilteredFunc={yesFilteredFunc}
                />
                {view ? (
                  isFilterLoading === true ? (
                    <Grid12All>
                      <Loader />
                    </Grid12All>
                  ) : (
                    <>
                      {champaignList?.length === 0 ||
                      filteDataNotFound === true ? (
                        <div className="outDfilterDNfound">
                          <p>Data not Found</p>
                        </div>
                      ) : (
                        <>
                          <Grid12All>
                            <Grid container>
                              {champaignList?.map((list, i) => {
                                return (
                                  <OutdoorItemCard
                                    key={list.campaignId}
                                    {...list}
                                    favouriteChange={favouriteChange}
                                  />
                                );
                              })}
                            </Grid>
                          </Grid12All>
                          <Grid12All classes="load-more-button-wrap">
                            {!islimitReact.limit ?
                            yesFiltered ? (
                              <Button
                                variant="contained"
                                onClick={extendDataFilter}
                              >
                                Load More
                              </Button>
                            ) : champaignList === null ||
                              champaignList === "" ||
                              champaignList === undefined ? null : (
                              <Button variant="contained" onClick={extendData}>
                                Load More
                              </Button>
                            )
                            :''}
                          </Grid12All>
                        </>
                      )}
                    </>
                  )
                ) : (
                  <Grid12All>
                    <FinalMap />
                  </Grid12All>
                )}
              </>
            )}
            <Details />
          </Grid>
        </GridCustomHead>
      </Box>
    </>
  );
};

export default Index;
