const initialState = {
    type:'',
    msg:'',
    isTrue:false,
  };
  function notificationReducer(state = initialState, action) {
    switch (action.type) {
      case "notificationTrigger":
        return {
          ...state,
          isTrue: true,
          msg: action.payload,
          type: action.typed,
        };
        case "notificationStop":
            return {
              ...state,
              isTrue: false,
              msg:'Stop Notification',
            };
      default:
        return state;
    }
  }
  
  export default  notificationReducer;