import * as React from 'react';
import { CardContent, Typography, CardActionArea, Paper } from '@mui/material';
import { CustomizedCardClient, CustomizedCardMediaClient, CustomizedBoxClient } from '../../../globalstyled/homeStyle'

const ClientSliderCard = ({ imgSrc, name, review, position }) => {
    return (
        <Paper>
            <CustomizedCardClient >
                <CardActionArea>
                    <CustomizedBoxClient>
                        <CustomizedCardMediaClient
                            component="img"
                            src={imgSrc}
                            alt={name}
                        />
                    </CustomizedBoxClient>
                    <CardContent className="cardcontent-wraper" >
                        <Typography className='review-para' gutterBottom align="justify" variant="body1" fontWeight={300} component="div">{review}</Typography>
                        <div>
                            <Typography gutterBottom align="right" variant="body1" fontWeight={800} component="div">{name}</Typography>
                            <Typography gutterBottom align="right" variant="body2" fontWeight={300} component="div">{position}</Typography>
                        </div>
                    </CardContent>
                </CardActionArea>
            </CustomizedCardClient>
        </Paper>
    );
}

export default ClientSliderCard;