import React from "react";
import { Grid, Modal, Box, Button } from "@mui/material";
import { CustomSlidBoxListing } from "../../../globalstyled/homeStyle";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "./slidermodal.scss";

Array.prototype.newFroEach = function (callback, context){
  for(let index=0; index< this.length; index++){
    if(this.indexOf(this[index])> -1){
      callback.call(context, this[index], index, this)
    }
  }
}


const SliderModal = ({ campaignImages, show, handleClose }) => {
  return (
    <Modal open={show} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" style={{backgroundColor:"#7c7c7c"}}>
      <Box className="slidermodal-modal-wrap">
        <CustomSlidBoxListing>
        <div className="upload-wrap-slider">
            <span className="upload-title-close" onClick={handleClose}>X</span>
          </div>
              <Swiper
                slidesPerView={1}
                spaceBetween={30}
                loop={true}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
              >
                {campaignImages.map((slider, id) => {
                  return (
                    <SwiperSlide key={id + "00"}>
                      <div className="myDiv">
                      <img className="img-banner-full" alt={slider} src={slider} />
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </CustomSlidBoxListing>
      </Box>
    </Modal>
  );
};

export default SliderModal;
