import React from "react";
import { Grid, Button, Box, Modal } from "@mui/material";

const MediaOptionListModal = ({ openModalList, handleModalListClose, item }) => {
  return (
    <Modal open={openModalList} onClose={handleModalListClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box className="upload-modal-wrap">
        <Grid container className="upload-container">
          <Grid item sm={12} md={12}>
            <div className="upload-wrap">
              <h4 className="upload-title">Media Detail</h4>
            </div>
          </Grid>
          <Grid item sm={12} md={12}>
            <Grid container>
              <Grid item xs={12} sm={12} md={6}>
                <h4 className="details-title">Filters</h4>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <h4 className="details-title">Rates</h4>
                  </Grid>
                  {Object.entries(item).map((i, k) => {
                    return (
                      <Grid container key={k}>
                        <Grid item xs={7} sm={7} md={7} position="relative">
                          <p className="details-list">{i[0]}</p>
                          <span className="dot-divider">:</span>
                        </Grid>
                        <Grid item xs={5} sm={5} md={5}>
                          <p className="details-list second">{i[1]}</p>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item sm={12} md={12}>
            <div className="add-cancel-btn-wrap">
              <Button className="btn-cancel" onClick={handleModalListClose} variant="text">
                Cancel
              </Button>
            </div>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default MediaOptionListModal;
