import React from "react";
import { Accordion, Typography, AccordionDetails, AccordionSummary } from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";

const ExecuteCard = (props) => {
  const state1 = [
    { AREA: props.area + " Sq. Ft." },
    { LANDMARK: props.landmark },
    { "Lead Time (in days)": props.leadTime },
    { "Proof of execution(in days)": "Start and end of the campaign" },
  ];
  const state2 = [
    { "CREATIVE LEAD TIME (IN DAYS)": props.creativeLeadTime },
    { "FIRST PROOF OF EXECUTION(IN DAYS)": props.firstExecutionProof },
    { "PROOF OF EXECUTION CADENCE": props.cadenceExecutionProof },
    { "CREATIVE DIMENSION WIDTH": props.dimensionWidth },
    { "CREATIVE DIMENSION HEIGHT": props.dimensionHeight },
    { "CREATIVE MEASUREMENT UNIT": props.measurementUnit },
    { "CREATIVE FORMAT": props.creativeFormat },
    // { },
  ];
  return (
    <div className="item-wrapper">
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography>Execution Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="item-details-list">
            <ul>
              <StatePreview stat={state1} />
              <li>
                <p>PROOF OF EXECUTION</p>
                <a target="_blank" href={`${props.executionProof}`}>
                  Click here to view
                </a>
              </li>
              <StatePreview stat={state2} />
              
              <li>
                <p>CAMPAIGN END REPORT</p>
                <a target="_blank" href={`${props.endReportUrl}`}>
                  Click here to view
                </a>
              </li>
              <li>
                <p>YOUTUBE LINK</p>
                <a target="_blank" href={`${props.youtubeLink}`}>
                  Click here to watch
                </a>
              </li>
            </ul>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const StatePreview = ({stat}) => <>
    {stat.map((s,index)=>{
      return (
        <li key={index}>
          <p>{Object.keys(s)}</p>
          <h6>{Object.values(s)}</h6>
        </li>
      )
    })}
</>

export default ExecuteCard;
