import { CloudDownload } from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import "./allcampaigndownloadbtn.scss";
import { deleteApiCall, getApiCall } from "../../common/axios";
import { API_URL } from "../../common/defines";

const AllCampaignDownloadBtn = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [excelData, setExcelData] = useState([]);
  useEffect(() => {}, []);

  const handleFetchExcel = async () => {
    try {
      const paramsData = {
        queryParams: {
          userId: Cookies.get('userId'),
          downloadType: "all-excel",
        },
        headers: {},
        isAuthorized: true,
      };
      const response = await getApiCall(API_URL + "dashboard/getDashboardData", paramsData);
      if (response.status == true) {
        let filter = response?.orderData.filter((excel)=>excel?.orderExcel?.length>1)
        setExcelData(filter);
      }
      // console.log("response- --",response.status)
      // let a = document.createElement("a");
      // a.href = response.data;
      // a.target = "_blank";
      // a.click();
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleBtnClick = async (excel) => {
    try {
      let a = document.createElement("a");
      a.href = excel;
      a.target = "_blank";
      a.click();
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    handleFetchExcel();
  }, []);


  return (
    <div className="allcampaigndownloadbtnContainer">
      {excelData.length > 0 ? (
        <>
          <div className="wrapDnloadContainer header">
            <div className="excelfileName">Campaign Name</div>
            <div className="excelfileName">Download</div>
          </div>
          {excelData.map((exl) => {
            if (exl.orderExcel.length > 0) {
              return (
                <div className="wrapDnloadContainer" key={exl.orderId}>
                  <div className="excelfileName">{exl.orderName}</div>
                  <Button
                    className="download-button-btn"
                    variant="contained"
                    onClick={() => {
                      handleBtnClick(exl.orderExcel);
                    }}
                    startIcon={<CloudDownload />}
                  >
                    Download
                  </Button>
                </div>
              );
            }
          })}
        </>
      ):
        <h3 className="notfound-downloadlink">No Data Avalaible</h3>
      }
    </div>
  );
};

export default AllCampaignDownloadBtn;
