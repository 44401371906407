import React from "react";
import { Grid, Modal, Box, Button } from "@mui/material";
import { ButtonBox } from "../../../globalstyled/dashboard";
import { CloudDownload } from "@mui/icons-material";
import { GridA } from "../../shared/GridWrap";
const DownloadModal = ({ excelClickHandler, openModal, handleModalClose }) => {
  return (
    <Modal open={openModal} onClose={handleModalClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box className="request-modal-wrap">
        <Grid container className="upload-container">
          <div className="upload-wrap">
            <h4 className="upload-title">Schedule</h4>
            <br />
            <ButtonBox variant="contained" onClick={excelClickHandler} startIcon={<CloudDownload />}>
              Download
            </ButtonBox>
          </div>
          <GridA size={[12,12,12,12]}>
            <div className="add-cancel-btn-wrap">
              <Button className="btn-cancel" variant="text" onClick={handleModalClose}>
                Close
              </Button>
            </div>
          </GridA>
        </Grid>
      </Box>
    </Modal>
  );
};

export default DownloadModal;
