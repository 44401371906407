import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { goTop } from "../../components/common/gFun/GFun";
import { ContactGridCustom, ContactContextGridCustom } from "../../globalstyled/homeStyle";

import ContactField from "../../components/contact/ContactField";
import "./contact.scss";
import CallMail from "../../components/contact/CallMail";
import FinalMap from "../../components/map/FinalMap";
import { Grid12All, GridA } from "../../components/shared/GridWrap";

const Contact = () => {
  useEffect(() => {
    goTop();
  });

  return (
    <>
      <ContactGridCustom>
        <div className="contact-us">
          <Grid container spacing={2} className="contact-container">
            <GridA size={[12, 12, 6, 5]} classes="contact-section">
              <ContactField />
            </GridA>
            <GridA size={[12, 12, 6, 7]} classes="info-section">
              <div className="info-container">
                <div className="info-warp">
                  <div className="info">Looking for more information?</div>
                  <div className="info">We would be glad to help you out!</div>
                </div>
              </div>
            </GridA>
          </Grid>
        </div>
      </ContactGridCustom>
      <ContactContextGridCustom>
        <CallMail />
      </ContactContextGridCustom>

      <ContactContextGridCustom>
        <Grid container justifyContent={"center"}>
          <Grid12All>
            <h3>
              <b>Reach us</b>
            </h3>
          </Grid12All>
          <GridA size={[12, 12, 8, 7]}>
            <FinalMap />
          </GridA>
        </Grid>
      </ContactContextGridCustom>
    </>
  );
};

export default Contact;
