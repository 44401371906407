import React, { useState } from "react";
import Cookies from "js-cookie";
import { Button, Stack, TextField } from "@mui/material";
import { useFormik } from "formik";
import GoogleBtn from "../common/GoogleBtn";
import { initialValues, validationSchema } from "../../../../common/validate/signup/Index";
import { API_URL } from "../../../../common/defines";
import LocalStorage from "../../../../services/local_storage";
import { useDispatch } from "react-redux";
import { postApiCall, getApiCall } from "../../../../common/axios";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
let acknoledgeMsg = "";
const Otp = ({ signInModal, signupHandle, otpModalHandle }) => {
  const [showMsg, setShowMsg] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  let localstorage = new LocalStorage();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      try {
        const signupDetails = {
          registerType: "register_with_email_pass",
          userName: values?.name,
          userEmail: values?.email,
          userPassword: values?.password,
          // mobilenumber: values?.mobilenumber,
        };
        apiLogin(signupDetails);
      } catch (err) {}
      formik.resetForm();
    },
  });
  const renderErrorMessage = (field) => {
    return formik.touched[field] && <div className="text-error text-err">{formik.errors[field]}</div>;
  };
  const apiLogin = async (reqBody) => {
    const paramsData = {
      queryParams: {},
      headers: {},
      isAuthorized: true,
    };
    try {
      const response = await postApiCall(API_URL + "authentication/register", reqBody, paramsData);
      if (response.status === false) {
        dispatch({
          type: "notificationTrigger",
          typed: "warning",
          payload: `${response.message}`,
        });
      } else if (response.status === true) {
        localstorage.login(true, response.data.login_token, response.data.userId);
        dispatch({ type: "login" });
        dispatch({ type: "isloadingload" });
        signupHandle();
        apiGetProfile();
      }
    } catch (error) {
      alert(error);
    }
  };
  const apiGetProfile = async () => {
    try {
      const paramsData = {
        queryParams: {},
        headers: {},
        isAuthorized: true,
      };
      const res = await getApiCall(API_URL + "profile/profileDetail/" + Cookies.get("userId"), paramsData);
      localstorage.setItem("avatarImg", res.data.userImage);
      dispatch({
        type: "notificationTrigger",
        payload: `Hi, ${res.data.userName}`,
      });
    } catch (err) {
      console.log("err", err);
    }
  };
  const clickIcon = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="login-field-container">
      <Stack sx={{ width: "100%" }} spacing={2}>
        {showMsg && acknoledgeMsg}
      </Stack>
      <Stack className="stack-wrapper" spacing={6}>
        <Button variant="text" onClick={signInModal}>
          Sign In
        </Button>
      </Stack>
      <div>
        <TextField label="Name *" variant="standard" className={"sl-field"} id="name" name="name" {...formik.getFieldProps("name")} />
        {renderErrorMessage("name")}
        <TextField label="Email *" variant="standard" className={"sl-field"} id="email" name="email" {...formik.getFieldProps("email")} />
        {renderErrorMessage("email")}
        {/* <TextField label="Mobile Number *" variant="standard" className={"sl-field"} id="mobilenumber" name="mobilenumber" {...formik.getFieldProps("mobilenumber")} />
        {renderErrorMessage("mobilenumber")} */}
        <TextField
          label="Password *"
          variant="standard"
          type={showPassword ? "text" : "password"}
          className={"sl-field"}
          id="password"
          name="password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {!showPassword === true ? (
                  <Visibility onClick={clickIcon} style={{ cursor: "pointer" }} />
                ) : (
                  <VisibilityOffIcon onClick={clickIcon} style={{ cursor: "pointer" }} />
                )}
              </InputAdornment>
            ),
          }}
          {...formik.getFieldProps("password")}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              formik.handleSubmit();
            }
          }}
        />
        {renderErrorMessage("password")}
      </div>
      <div className="btn-trig-container">
        <Button
          variant="contained"
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          Sign Up
        </Button>
      </div>
      <div className="txt-otp-wrap">
        <Button variant="text" className="txt-otp" onClick={otpModalHandle}>
          Have OTP?
        </Button>
      </div>
      <GoogleBtn loginHandle={signupHandle} type={"signup"} title={"Sign Up"} />
    </div>
  );
};
export default Otp;
