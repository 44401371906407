import { styled } from '@mui/material/styles';
import {Button,Grid} from '@mui/material';


export const ButtonIcon = styled(Button)`
    min-width:24px;
`;

export const CheckGrid = styled(Grid)(({ theme }) => ({
    
    [theme.breakpoints.down('md')]: {
      display:'none'
    },
  }));

  export const ButtonBox = styled(Button)(({ theme }) => ({
    
    // [theme.breakpoints.down('md')]: {
    //   display:'none'
    // },
  }));