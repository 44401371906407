import React from 'react'
import ReactDOM from 'react-dom'
import LoginModal from './SignLog/LoginModal'
import SignUpModal from './SignLog/SignUpModal'
import "../../globalstyled/common/signinup/signup.scss";
const Modal = () => {
  return (
    ReactDOM.createPortal(
        <>
        <LoginModal />
        <SignUpModal />
        
        </>,
        document.getElementById('modal-root')
      )
      
  )
}

export default Modal