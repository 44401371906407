import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

export const HeroGrid = styled('div')(({ theme }) => ({
  position: 'relative',
  // minHeight: '60vh',
  paddingBottom:'20px',
  paddingTop:'20px',
  // backgroundImage: `url(${"img/hero-bg.png"})`,

  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 'auto',
  },
}));

export const GridCustom = styled('div')(({ theme }) => ({
    display: 'flex',
    margin: 'auto',
    width: '88%',
    marginTop: '72px',
    [theme.breakpoints.down('md')]: {
      width: '96%',
      margin: '0 auto',
      marginTop: '78px'
    },
  }));

  export const ContactGridCustom = styled('div')(({ theme }) => ({
    display: 'flex',
    margin: 'auto',
    width: '84%',
    marginTop: '72px',
    [theme.breakpoints.down('md')]: {
      width: '96%',
      margin: '0 auto',
      marginTop: '78px',
    },
  }));

  export const ContactContextGridCustom = styled('div')(({ theme }) => ({
    display: 'flex',
    margin: 'auto',
    width: '84%',
    marginTop: '42px',
    marginBottom:'42px',
    [theme.breakpoints.down('md')]: {
      width: '96%',
      margin: '0 auto',
      marginTop: '48px',
      marginBottom:'48px',
    },
  }));

export  const CustomFooter = styled('div')(({ theme }) => ({
    display: 'flex',
    margin: 'auto',
    width: '88%',
    [theme.breakpoints.down('md')]: {
      width: '96%',
      margin: '0 auto',
    },
  }));

export const CustomSlidBox = styled(Box)`
    margin-bottom:10px;
    margin-top:10px;
    & :hover{
        background-color:none;
    }
    & div.swiper div.swiper-button-prev, div.swiper div.swiper-button-next {
        border-radius:50%;
        background-color:#fff;
        width:36px;
        height:38px;
        box-shadow:0 2px 5px #aeaeae;
        color:#000;
    }
    & div.swiper div.swiper-button-prev::after, div.swiper div.swiper-button-next::after {
        font-size:12px;
        font-weight:800;
    }
`;

export const CustomSlidBoxListing = styled(Box)`
    margin-bottom:10px;
    margin-top:10px;
    & :hover{
        background-color:none;
    }
    & div.swiper div.swiper-button-prev, div.swiper div.swiper-button-next {
        border-radius:50%;
        background-color:#fff;
        width:36px;
        height:38px;
        box-shadow:0 2px 5px #aeaeae;
        color:#000;
    }
    & div.swiper div.swiper-button-prev::after, div.swiper div.swiper-button-next::after {
        font-size:12px;
        font-weight:800;
    }
`;

export const CustomHeroSlidBox = styled(Box)`
    margin-bottom:10px;
    & :hover{
        background-color:none;
    }
    & div.swiper div.swiper-button-prev, div.swiper div.swiper-button-next {
        border-radius:50%;
        background-color:#fff;
        width:36px;
        height:38px;
        box-shadow:0 2px 5px #aeaeae;
        color:#000;
    }
    & div.swiper div.swiper-button-prev::after, div.swiper div.swiper-button-next::after {
        font-size:12px;
        font-weight:800;
    }
`;

export const CustomSlidBoxClient = styled(Box)`
    margin-bottom:30px;
    & :hover{
        background-color:none;
    }
    & div.swiper div.swiper-button-prev, div.swiper div.swiper-button-next {
        border-radius:50%;
        background-color:#fff;
        width:36px;
        height:38px;
        box-shadow:0 2px 5px #aeaeae;
        color:#000;
        
    }
    & div.swiper div.swiper-button-prev{left:0px;}
    & div.swiper div.swiper-button-next {right:0px;}
    & div.swiper div.swiper-button-prev::after, div.swiper div.swiper-button-next::after {
        font-size:12px;
        font-weight:800;
    }
`;

export const CustomizedCard = styled(Card)`
    transition: all 0.3s;
    max-width: 345px;
    :hover {
        // transform: scale(1.1);
        box-shadow: 0px 1px 8px 3px grey
    }
    padding:6px;
    border-radius:4px;
    margin:6px 0 6px 0;
  `;

  export const CustomizedOutdoorCard = styled(Card)`
  transition: all 0.3s;
  :hover {
      // transform: scale(1.1);
      box-shadow: 0px 1px 8px 1px grey
  }
  padding:6px;
  border-radius:4px;
  margin:6px 0 6px 0;
`;

  export const CustomizedHeroCard = styled(Card)`
    transition: all 0.3s;
    max-width: 100%;
    :hover {
        // transform: scale(1.1);
        // box-shadow: 0px 1px 8px 3px grey
    }
    padding:0px;
    border-radius:4px;
    margin:6px 0 6px 0;
  `;
  export const CustomizedCardClient = styled(Card)`
    padding:8px 2px;
    transition: all 0.7s;
    background:transparent; 
    box-shadow:none; max-width: 345px;
    :hover {
        background:none;
    }
    border-radius:0;
    
  `;
export const CustomizedCardMedia = styled(CardMedia)`
  color: #20b2aa;
  // object-fit:contain;
`;

export const CustomizedHeroCardMedia = styled(CardMedia)(({ theme }) => ({
  color: "#20b2aa",
  height:'500px',
  backgroundPosition:'left center',
  objectFit: 'fill',
  [theme.breakpoints.down('lg')]: {
    height:'400px',
  },
  [theme.breakpoints.down('md')]: {
    height:"300px"
  },

}));

export const CustomizedCardMediaClient = styled(CardMedia)`
  color: #20b2aa;
  height: 110px;
  object-fit:contain;
`;

export const CustomizedBox = styled(Box)`
  position:relative;
  &::after {
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      bottom: 0;
      height: auto;
      content: '';
      position: absolute;
      background: linear-gradient(50deg,hsla(0,0%,100%,0),rgba(0,0,0,.2)),linear-gradient(-65deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0) 50%,hsla(0,0%,100%,.1) 0,hsla(0,0%,100%,.1));
  
  }
`;

export const CustomizedHeroBox = styled(Box)`
  position:relative;
  &::after {
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      bottom: 0;
      height: auto;
      content: '';
      position: absolute;
      // background: linear-gradient(50deg,hsla(0,0%,100%,0),rgba(0,0,0,.2)),linear-gradient(-65deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0) 50%,hsla(0,0%,100%,.1) 0,hsla(0,0%,100%,.1));
  
  }
`;

export const CustomizedBoxClient = styled(Box)`
  position:relative;
  padding:20px 10px;
  &::after {
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      bottom: 0;
      height: auto;
      content: '';
      position: absolute;
      
  }
`;

/*Main Content Page */
export const CustomSectionService = styled('div')(({ theme }) => ({
    display: 'flex',
    minHeight:'70vh',
    backgroundColor: '#d7d7d7',
    width: '100%',
    alignItem:'center'
}));

export const CustomSectionClient = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems:'center',
    minHeight:'85vh',
    // backgroundColor: '#e7e8e8',
    width: '100%',
}));
export const CustomSectionOutdoorSlider = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems:'center',
  minHeight:'85vh',
  
  width: '100%',
}));