import React from "react";
import "./style.scss";
import MapWrapper from "./MapWrapper";

function FinalMap() {
  return (
    <div>
      <div className="">
        <div className="mt-1 container">
          <MapWrapper />
        </div>
      </div>

    </div>
  );
}

export default FinalMap;
