import React from "react";
import { Close as CloseIcon } from "@mui/icons-material";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import MapTable from "../../map/MapTable";
import MapWrapper from "../../map/MapWrapper";

const CamMapModal = ({ cName, show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose} className="modalTop">
      <div className="modal-header">
        <div className="d-flex text-center">
          <h6 className="modal-title" id="exampleModalLabel">
            {cName}
          </h6>
        </div>
        <button type="button" className="close" onClick={handleClose}>
          <CloseIcon className="closebtn" onClick={handleClose} />
        </button>
      </div>
      <Modal.Body>
        <div>
          <div className="row">
            <div className="col-md-5">
              <MapTable />
            </div>
            <div className="col-md-6 fixMap">
              <MapWrapper />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose} className="updateBtn">
          UPDATE
        </Button>
        <Button className="updateBtn" onClick={handleClose}>
          CANCEL
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CamMapModal;
