import React, { useState } from "react";
import Cookies from 'js-cookie'
import { useDispatch } from "react-redux";
import { Menu, Box, IconButton, MenuItem, Button } from "@mui/material";
import {MoreVert,CloudDownload} from "@mui/icons-material";
import DownloadModal from "../modal/dashboard/DownloadModal";
import { API_URL } from "../../common/defines";
import { deleteApiCall, getApiCall } from "../../common/axios";

let a = document.createElement("a");
const OptionList = ({ setDeleteCampaign, id }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const deleteCampaignHandler = async () => {
    try {
      const reqBody = {};
      const paramsData = {
        queryParams: {},
        headers: {},
        isAuthorized: true,
      };
      const response = await deleteApiCall(
        API_URL + `dashboard/deleteOrder/${id}`,
        reqBody,
        paramsData
      );
      dispatch({ type: "notificationTrigger", payload: `${response.message}` });
      setDeleteCampaign(true)
    } catch (error) {
      console.log(`Error in Copy cart ${error}`);
    }
  }
  const handleModalOpen = async () => {
    handleClose();
    setOpenModal(true)
  };
  const handleModalClose = () => setOpenModal(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const emailClickHandler = async() => {
    try{
      const paramsData = {
        queryParams: {
          orderId: id,
          userId:Cookies.get('userId'),
          downloadType:'email'
        },
        headers: {},
        isAuthorized: true,
      };
      const response = await getApiCall(API_URL + "dashboard/dashboardActions", paramsData);
      dispatch({
        type: "notificationTrigger",
        payload: `${response.message}`,
      });
    }catch(err){
      console.log('err', err)
    }
  }

  const excelClickHandler = async() => {
    try{
      const paramsData = {
        queryParams: {
          orderId: id,
          userId:Cookies.get('userId'),
          downloadType:'excel'
        },
        headers: {},
        isAuthorized: true,
      };
      const response = await getApiCall(API_URL + "dashboard/dashboardActions", paramsData);
      dispatch({
        type: "notificationTrigger",
        payload: 'Download Successfully'
      });
      handleModalClose()
      a.href = response.data;
      a.target="_blank"
      a.click();
    }catch(err){
      console.log('err', err)
    }
  }

  return (
    <Box className="option-wrapper">
      <Button
        className="download-button-btn"
        variant="contained"
        onClick={handleModalOpen}
        startIcon={<CloudDownload />}
      >
        Download
      </Button>
      <DownloadModal excelClickHandler={excelClickHandler} openModal={openModal} handleModalClose={handleModalClose} />
      <div className="option-list-container">
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVert />
        </IconButton>
        <Menu
        className='option-menu-list-wrap'
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: "20ch",
            },
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem className="option-menu-item" onClick={()=>{handleClose(); emailClickHandler()}}>Email</MenuItem>
          <MenuItem className="option-menu-item" onClick={handleClose}>Pay</MenuItem>
          <MenuItem className="option-menu-item" onClick={()=> { deleteCampaignHandler() }}>Delete</MenuItem>
          <MenuItem className="option-menu-item delete" onClick={handleModalOpen}>Download</MenuItem>
        </Menu>
      </div>
    </Box>
  );
};

export default OptionList;
