import React, { userState, useEffect } from "react";

const Geolocation = () => {
    useEffect(() => {
        if (navigator.geolocation) {
            navigator.permissions
                .query({ name: "geolocation" })
                .then(function (result) {
                    // alert(result.state)
                    if (result.state === "granted") {
                        navigator.geolocation.getCurrentPosition((position) => {
                            // setStatus(null);
                            // setLat(position.coords.latitude);
                            // setLng(position.coords.longitude);
                            // console.log('position', position.coords.latitude, position.coords.longitude);
                        }, () => {
                            // setStatus('Unable to retrieve your location');
                        });
                        //If granted then you can directly call your function here
                    } else if (result.state === "prompt") {
                        navigator.geolocation.getCurrentPosition((position) => {
                            // setStatus(null);
                            // setLat(position.coords.latitude);
                            // setLng(position.coords.longitude);
                            // console.log('position',position.coords);
                        }, () => {
                            // setStatus('Unable to retrieve your location');
                        });

                    } else if (result.state === "denied") {
                        //If denied then you have to show instructions to enable location
                        if ((window.location.pathname === '/') || (window.location.pathname === '/dashboard')) { alert('Please allow location') }
                    }
                    result.onchange = function () {
                    };
                });
        } else {
            alert("Sorry Not available!");
        }
    }, []);



    return <></>;
};

export default Geolocation;