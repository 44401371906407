import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";

const CityAutoComplete = ({ cityList, cityIdHandler, AreaHandleApi }) => {
  const [value2, setValue2] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  const cityed = cityList?.map((name) => {
    return name.cityName;
  });
  useEffect(() => {
    setValue2("");
  }, [cityList]);

  const SearchCityHandle = (event, newValue2) => {
    const getId = cityList?.filter((name) => name.cityName.toLowerCase() === newValue2.toLowerCase());
    cityIdHandler(getId[0].cityId);
    if (cityed.includes(newValue2.toLowerCase()) === -1) {
    } else {
      // console.log('hi ther elsdkfl ex history', window.location.search.includes('&') ===true)
      window.history.replaceState(
        null,
        "/outdoor" + window.location.search,
        "/outdoor" + (window.location.search.includes("&") === true ? window.location.search.split("&")[0] : window.location.search) + "&city=" + newValue2.toLowerCase()
      );
    }
    setValue2(newValue2);
    AreaHandleApi(cityList[cityed.indexOf(newValue2)].cityId);
    // console.log('city  - id - - >',cityList[cityed.indexOf(newValue2)].cityId)
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <Autocomplete
        disablePortal
        value={value2}
        onChange={(event, newValue2) => {
          SearchCityHandle(event, newValue2);
        }}
        inputValue={inputValue2}
        onInputChange={(event, newInputValue) => {
          setInputValue2(newInputValue);
        }}
        id="city-search-outdoor"
        size="small"
        options={cityed || [""]}
        renderInput={(params) => <TextField {...params} variant="standard" label="Type to search city" placeholder="" />}
      />
    </div>
  );
};

export default CityAutoComplete;
