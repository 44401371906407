import React,{useState} from 'react';
import { Tab, Tabs, Box } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Scheduler from './Scheduler';
import TabPanel from './TabPanel';
import Uploads from './Uploads';
// import Users from './Users';

function a11yProps(index) {
  return {
    id: `champaign-tab-${index}`,
    'aria-controls': `champaign-tabpanel-${index}`,
  };
}

export default function BasicTabs({campaginDetails}) {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };
  
  return (
    <Box className="tabs-wrapper">
      <Box className="tab-head">
        <Tabs className="tabs-container" value={value} onChange={handleChange} aria-label="champaign tabs">
          <Tab className="tab-item" label={"Scheduler".toUpperCase()} {...a11yProps(0)} />
          {/* <Tab className="tab-item" label={"Users".toUpperCase()} {...a11yProps(1)} /> */}
          <Tab className="tab-item" label={"Uploads".toUpperCase()} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0}>
          <Scheduler  campaginDetails = {campaginDetails}/>
        </TabPanel>
        {/* <TabPanel value={value} index={1}>
          <Users />
        </TabPanel> */}
        <TabPanel value={value} index={1}>
          <Uploads />
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
}
