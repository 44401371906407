import React, { useState } from "react";
import { Grid, Modal, Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

import LogIn from "./login/LogIn";
import Forgot from "./login/Forgot";

const LoginModal = () => {
  const login = useSelector((state) => state.loginReducer.login);
  const loginModal = useSelector((state) => state.loginReducer.loginModal);
  const [isforgotModalOpen, setIsForgotModalOpen] = useState(false);
  const dispatch = useDispatch();
  function modalClose() {
    if (loginModal === true) {
      dispatch({ type: "loginmodal" });
    }
  }
  function signUpModal() {
    if (loginModal === true) {
      dispatch({ type: "loginmodal" });
      dispatch({ type: "signupmodal" });
    }
  }
  function forgetModalHandle() {
    setIsForgotModalOpen(!isforgotModalOpen);
  }
  function loginHandle() {
    
    if (login === false) {
      modalClose();
    }
  }
  
  return (
    <Modal
      open={loginModal}
      onClose={modalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="signup-box">
        <Grid container className="signup-container">
          <Grid item md={6} lg={6} xl={6} className="left-logo">
            <div className="signup-login-img-wrap">
              <div className="signup-login-img"></div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className="right-field"
          >
            <div className="signup-form-wrap">
              <Grid container justifyContent={"center"}>
                <Grid item xs={11} sm={11} md={10} lg={10} xl={8}>
                  {isforgotModalOpen ? (
                    <Forgot
                      forgetModalHandle={forgetModalHandle}
                      loginHandle={loginHandle}
                      setIsForgotModalOpen={setIsForgotModalOpen}
                    />
                  ) : (
                    <LogIn
                      signUpModal={signUpModal}
                      loginHandle={loginHandle}
                      forgetModalHandle={forgetModalHandle}
                      setIsForgotModalOpen={setIsForgotModalOpen}
                    />
                   )} 
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
export default LoginModal;
