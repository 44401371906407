
import { hashById } from '../calender/utils'
import Cookies from 'js-cookie'
export function weekendsVisible(weekendsVisible = true, action) {
  switch (action.type) {

    case 'TOGGLE_WEEKENDS':
      return !weekendsVisible

    default:
      return weekendsVisible
  }
}

export function eventsById(eventsById = {}, action) {
  switch (action.type) {

    case 'RECEIVE_EVENTS':
      // console.log('state',eventsById)
      return hashById(action.plainEventObjects)

    case 'CREATE_EVENT':
    case 'UPDATE_EVENT':
      // console.log('js slkdjfls', action)
      
      return {
        ...eventsById,
        [action.plainEventObject.id]: action.plainEventObject
      }

    case 'DELETE_EVENT':
      // console.log("event sBy I d",eventsById[action.eventId].date)
      Cookies.set('date',eventsById[action.eventId].date)
      eventsById = {...eventsById} // copy
      delete eventsById[action.eventId]
      // store.dispatch({
      //   type: 'orderremove',
      //   payload:eventsById[action.eventId].date
      // })
      return eventsById

    default:
      return eventsById
  }
}
