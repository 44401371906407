import React, { useEffect, useState } from "react";
import { Grid, Box, Modal } from "@mui/material";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import Moment from "moment";
import { extendMoment } from "moment-range";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch } from "react-redux";
import { GridA } from "../../shared/GridWrap";

const MediaOptionListModal = ({ openModalList, handleModalListClose, item, dateDate, dateDataHanler, campaginDetails }) => {
  // console.log("campaginDetails", campaginDetails);
  const moment = extendMoment(Moment);
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(dateDate?.start);
  const [endDate, setEndDate] = useState(dateDate?.end);
  const [focusedInput, setFocusedInput] = useState(null);
  const [bookingUp, setBookingUP] = useState([]);
  const [bookings, setBookings] = useState([]);

  const strReplace = (str) => {
    return moment(new Date(str?.replace("-", ",")));
  };

  useEffect(() => {
    let endFiltDate = campaginDetails?.orderItems[0]?.endDate;
    let startFiltDate = campaginDetails?.orderItems[0]?.startDate;
    let filetD = campaginDetails?.bookingDates?.map((b) => {
      return {
        start: strReplace(b?.startDate),
        end: strReplace(b?.endDate),
      };
    });

    let newBoked = {
      start: strReplace(startFiltDate),
      end: strReplace(endFiltDate),
    };

    if (filetD) {
      filetD = [...filetD, newBoked];
      setBookings(filetD);
      setBookingUP(filetD);
    }
  }, [campaginDetails]);

  // console.log("bookeing  - -- >", bookings);

  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    // console.log(startDate, endDate);
    /* this function is used to filter the dates that are already booked on start date selection. */
    if (startDate !== null) {
      for (let booked of bookings) {
        if (moment(startDate.format("Y-MM-D")).diff(moment(booked.start).format("Y-MM-D"), "days") < 0) {
          let bookedFilter = bookings;
          bookedFilter = bookedFilter.map((book) => {
            if (moment(book.start).format("Y-MM-D") === moment(booked.start).format("Y-MM-D")) {
              return {
                start: book.start,
              };
            }
            return book;
          });
          setBookings(bookedFilter);
          break;
        }
      }
    }

    /* This is the function that is called when the user selects the start date and end date. */
    if (startDate != null && endDate != null) {
      let SDATE = moment(startDate.format("Y-MM-D")),
        EDATE = moment(endDate.format("Y-MM-D"));
      let DAYS = EDATE.diff(SDATE, "days") + 1;
      // console.log("date range", DAYS);
      dispatch({
        type: "orderupdate",
        payload: {
          start: startDate.format("Y-MM-D"),
          end: endDate.format("Y-MM-D"),
          DAYS: DAYS,
        },
      });
      dateDataHanle({
        start: startDate.format("Y-MM-D"),
        end: endDate.format("Y-MM-D"),
        DAYS: DAYS,
      });

      setTimeout(() => {
        handleModalListClose();
      }, 1000);
    }
  };

  /** It takes a date, checks if it's in any of the booked ranges, and returns true if it is */
  const isBlocked = (date) => {
    date = date.set("hour", "00").set("minute", "00");
    let bookedRanges = [];
    let blocked;
    bookings.map((booking) => {
      bookedRanges = [...bookedRanges, moment.range(booking.start, booking.end)];
    });
    blocked = bookedRanges.find((range) => {
      return range.contains(date);
    });
    return blocked;
  };

  /** It resets the start and end dates to null, and then sets the bookings to the original bookingUp array  */
  const resetHandle = () => {
    setStartDate(null);
    setEndDate(null);
    setBookings(bookingUp);
  };

  const dateDataHanle = (data) => {
    dateDataHanler(data);
  };

  return (
    <Modal open={openModalList} onClose={handleModalListClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box className="upload-modal-wrap dattte">
        <Grid container className="upload-container dattte">
          <GridA size={[12,12,12,12]}>
            <Grid container className="date-wrappi">
              <div className="datepickercontainer">
                <DateRangePicker
                  startDate={startDate}
                  // isDayHighlighted={day1 => this.returnDates().some(day2 => isSameDay(day1, day2))}
                  // isDayBlocked={day1 => this.returnDates().some(day2 => isSameDay(day1, day2))}
                  // isDayBlocked={isDayBlocked(3)}
                  isDayBlocked={isBlocked}
                  startDateId="tata-start-date"
                  endDate={endDate}
                  endDateId="tata-end-date"
                  onDatesChange={handleDatesChange}
                  focusedInput={focusedInput}
                  // isOutsideRange={isBlocked}
                  isOutsideRange={(day) => {
                    // console.log("dayyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy",moment())
                    let date = new Date();
                    return moment(date.setDate(date.getDate() - 1)).diff(day) >= 1;
                  }}
                  // maxDate={moment(new Date())}
                  onFocusChange={(focusedInput) => {
                    setFocusedInput(focusedInput);
                  }}
                />

                <div className="datereset-campaign">
                  <Tooltip title="Clear Date" placement="top-end" arrow>
                    <EventBusyIcon onClick={resetHandle} />
                  </Tooltip>
                </div>
              </div>
            </Grid>
          </GridA>
        </Grid>
      </Box>
    </Modal>
  );
};

export default MediaOptionListModal;
