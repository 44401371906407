import React, { useEffect, useState } from "react";
import { goTop } from "../gFun/GFun";
import { getApiCall } from "../../../common/axios";
import { GridCustom } from "../../../globalstyled/homeStyle";
import Loader from "../Loader";
import TitleDescription from "../About/TitleDescription";
import { API_URL } from "../../../common/defines";
import { useParams } from "react-router-dom";

const AdvertisingComp = () => {
  const [about, setAbout] = useState({ title: "", description: null });
  const [isLoading, setIsLoading] = useState({ status: true, msg: null });
  const pageId = useParams().pageid;

  const pageApiCall = (api) => {
    goTop();
    setTimeout(() => {
      api();
    }, 600);
  };

  useEffect(() => {
    pageApiCall(pageContentApi);
    return () => {
      setIsLoading({ status: true, msg: null });
    };
  }, [pageId]);

  const pageContentApi = async () => {
    try {
      const paramsData = {
        queryParams: {
          pageType: pageId,
        },
      };
      const res = await getApiCall(API_URL + "popular/getPopularPageData", paramsData);
      if (res?.status) {
        setAbout({
          description: res.data.pageContent,
          title: res.data.pageHeading,
        });
        setIsLoading((prev) => {
          return { ...prev, status: false, msg: null };
        });
      } else {
        setIsLoading((prev) => {
          return { ...prev, status: false, msg: res?.message || "Something went wrong!" };
        });
      }
    } catch (err) {
      console.log("err", err);
      setIsLoading((prev) => {
        return { ...prev, status: false, msg: "Something went wrong!" };
      });
    }
  };

  return (
    <GridCustom>
      {isLoading.status ? (
        <Loader />
      ) : (
        <>
          {isLoading.msg ? (
            <div style={{ display: "block", marginTop: "12rem", marginBottom: "22rem", width: "100%", textAlign: "center" }}>
              <h3 style={{ color: "#ff00008c" }}>{isLoading.msg}</h3>
            </div>
          ) : (
            <TitleDescription title={about.title} description={about.description} />
          )}
        </>
      )}
    </GridCustom>
  );
};

export default AdvertisingComp;
