import React from 'react';
import {Route, Redirect } from 'react-router-dom';

import { useSelector,useDispatch } from "react-redux";

function PrivateRoute({ children ,...rest}) {
  const login = useSelector((state) => state.loginReducer.login);
  const dispatch = useDispatch();
    return (
        <Route
          {...rest}
          render={({ props }) =>
            (login === true) ? (
              children
            ) : (
              dispatch({ type: "notificationTrigger", typed:'warning', payload: `Login to access this section ` }),
              dispatch({ type: "loginmodal" }),
              <Redirect
                to={{
                  pathname: "/", 
                  loginModal:true,              
                }}
              />
            )
          }
        />
      );
    
  }

  export {PrivateRoute};