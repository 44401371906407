const initialState = {
    type:'',
    title:'',
  };
  function dashboardReducer(state = initialState, action) {
    switch (action.type) {
      case "titleTrigger":
        return {
          ...state,
          title:action.payload,
        };
        case "typeTrigger":
            return {
              ...state,
              type:action.payload,
            };
      default:
        return state;
    }
  }
  
  export default  dashboardReducer;