import React,{useState} from 'react';
import '../../globalstyled/common/input.scss'
import { useDispatch } from "react-redux";
export default function BasicTextFields() {
  const [search, setSearch]= useState('');
  const dispatch = useDispatch();
  return (
    <div className="standard-input-wrapper">      
      <input onChange={(e)=>{setSearch(e.target.value);dispatch({type:"titleTrigger",payload:e.target.value})}} value={search} className="standard-lable-input" type="text" placeholder=" "/>
      <span className="highlight"></span>
      <label className="standard-label">Search by name</label>
    </div>
  );
}
