import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Typography, Grid, Button } from "@mui/material";
import { goTop } from "../../components/common/gFun/GFun";
import {
  GridCustom,
  CustomSectionClient,
  CustomSectionOutdoorSlider,
} from "../../globalstyled/homeStyle";
import './home.scss'
import Hero from "../../components/home/HeroSlider";
import ClientSlider from "../../components/home/ClientSlider";
import OutdoorSlider from "../../components/home/OutdoorSlider";
const Home = (props) => {
  useEffect(() => {
    goTop();
  }, []);
  return (
    <>
      <GridCustom>
        <Grid container>
          <Grid item xs={12}>
            <Hero />
            <CustomSectionOutdoorSlider>
              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Grid align="center" item xs={12} sm={10} md={10} xl={10}>
                    <div className="outdoor-title-wrap">
                      <h1 className="outdoor-slider-title">Outdoor</h1>
                      <Button
                        variant="contained"
                        className="outdoor-slider-btn"
                      >
                        <Link to="/outdoor">View More</Link>
                      </Button>
                    </div>
                    <hr className="divider-outdoot-title" />
                    <OutdoorSlider />
                    <hr />
                  </Grid>
                </Grid>
              </Grid>
            </CustomSectionOutdoorSlider>

            <CustomSectionClient>
              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <Grid align="center" item xs={12} sm={10} md={10} xl={10}>
                    <Typography className="client-title" variant="h5">
                      Here's What Our Clients Have To Say
                    </Typography>
                    <ClientSlider />
                  </Grid>
                </Grid>
              </Grid>
            </CustomSectionClient>
          </Grid>
        </Grid>
      </GridCustom>
    </>
  );
};

export default Home;
