import React from 'react';
import PropTypes from 'prop-types';
import { GridC } from '../shared/GridWrap';

export default function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`champaign-tabpanel-${index}`}
            aria-labelledby={`champaign-tab-${index}`}
            {...other}
        >
            {value === index && (
                <GridC clx={`tab-grid-wrap ${props.campaingtab}`}>
                    {children}
                </GridC>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
