import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import PersonPinCircleRoundedIcon from "@mui/icons-material/PersonPinCircleRounded";
import Cookies from "js-cookie";
import { getApiCall } from "../../common/axios";
import { API_URL } from "../../common/defines";

const MyLocation = () => <PersonPinCircleRoundedIcon style={{ color: "orange" }} />;

const MapViewBtn = () => {
  const [is404, setIs404] = useState(false);
  const [apiKeys, setApiKeys] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [champaignListMap, setChampaignListMap] = useState();
  const [islimitReactMap, setIslimitReactMap] = useState({
    limit: false,
  });
  const [qParamsListMap, setQParamsListMap] = useState({
    pageNo: 0,
    limit: 0,
    firstTimeLoad: true,
  });
  const location = {
    address: "1600 Amphitheatre Parkway, Mountain View, california.",
    lat: 77.3800187,
    lng: 28.6293012,
  };

  const [defaultMarker, setDefaultMarker] = useState({
    center: {
      lat: 26.7372224,
      lng: 83.3737058,
    },
    zoom: 8,
  });

  const Marker = ({ options, link }) => {
    if (options === "Hoarding") {
      return (
        <img
          src={require("./../../assets/img/Holding.png")}
          onClick={() => {
            link?.length > 0 && window.open(link, "_blank");
          }}
        />
      );
    } else if (options === "Unipole/Sidepole") {
      return (
        <img
          src={require("./../../assets/img/UnitPole.png")}
          onClick={() => {
            link?.length > 0 && window.open(link, "_blank");
          }}
        />
      );
    } else if (options === "Led Wall") {
      return (
        <img
          src={require("./../../assets/img/L(media_ant).png")}
          onClick={() => {
            link?.length > 0 && window.open(link, "_blank");
          }}
        />
      );
    } else {
      return (
        <img
          src={require("./../../assets/img/red-marker-40.png")}
          onClick={() => {
            link?.length > 0 && window.open(link, "_blank");
          }}
        />
      );
    }
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setDefaultMarker({
            center: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
            zoom: 8,
          });
        },
        () => {
          setDefaultMarker({
            center: {
              lat: 26.7372224,
              lng: 83.3737058,
            },
            zoom: 8,
          });
        }
      );
    }
  }, []);

  const apiGetMapKey = async () => {
    setIsLoading(true);
    try {
      const res = await getApiCall(API_URL + "dashboard/getMapApiKey");
      if (res.message === "Data Not Found.") {
        setIs404(true);
        setIsLoading(false);
      } else if (res.status === true) {
        setIsLoading(false);
        setApiKeys(res.apiKey);
      }
    } catch (err) {}
  };

  useEffect(() => {
    apiGetMapKey();
  }, []);

  const apiGetChampaingList = async () => {
    qParamsListMap.firstTimeLoad === true && setIsLoading(true);
    try {
      const queryParams = {
        userId: Cookies.get("userId"),
      };
      const paramsData = { queryParams, headers: {}, isAuthorized: true };
      const res = await getApiCall(API_URL + "dashboard/getDashboardData", paramsData);
      qParamsListMap.firstTimeLoad === true && setIsLoading(false);
      if (res.orderData.length === qParamsListMap.limit) {
        setIslimitReactMap((prev) => {
          return {
            ...prev,
            limit: false,
          };
        });
      } else {
        setIslimitReactMap((prev) => {
          return {
            ...prev,
            limit: true,
          };
        });
      }
      setChampaignListMap(res.orderData);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    apiGetChampaingList();
  }, []);
  return (
    <div style={{ width: "100vw", height: "700px" }}>
      {defaultMarker?.center.lat && apiKeys && champaignListMap?.length > 0 ? (
        <GoogleMapReact bootstrapURLKeys={{ key: apiKeys }} defaultCenter={defaultMarker?.center} defaultZoom={defaultMarker?.zoom} yesIWantToUseGoogleMapApiInternals>
          {champaignListMap.map((items, index) => {
            if (!(items?.lattitude === "") || !(items?.longitude === "")) {
              return <Marker lat={items?.lattitude} lng={items?.longitude} options={items.mediaOption} key={index} link={items?.markerUrl} />;
            }
          })}

          {/* <MyLocation
                lat={defaultMarker?.center.lat}
                lng={defaultMarker?.center.lng}
            /> */}
        </GoogleMapReact>
      ) : (
        "Please Wait"
      )}
    </div>
  );
};

export default MapViewBtn;
