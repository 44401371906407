import React from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import actionCreators from "../../state/actions/CalenderActions";
import { getHashValues } from "../../state/calender/utils";
import { store } from "../../state/store";
import "./calender.scss";

class DemoApp extends React.Component {
  constructor(props) {
    super(props);
    this.calenderRef = React.createRef();
  }
  render() {
    // console.log('this. calender ref', this?.calenderRef?.current?._calendarApi?.currentDataManager?.data?.viewApi)
    // console.log('hi there ', this.props)

    return (
      <div className="calender-wrap">
        <div className="calender-container">
          <FullCalendar
            ref={this.calenderRef}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay",
            }}
            buttonText={{
              today: "Today",
              month: "Month",
              week: "Week",
              day: "Day",
              list: "list",
            }}
            height={650}
            contentHeight={600}
            aspectRatio={2}
            // dayMaxEventRows = {true}
            // views= {{
            //   timeGrid: {
            //     dayMaxEventRows: 5 // adjust to 6 only for timeGridWeek/timeGridDay
            //   }
            // }}
            onClickDay={(day) => console.log(day) }
            initialView="dayGridMonth"
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={this.props.weekendsVisible}
            datesSet={this.handleDates}
            select={this.handleDateSelect}
            events={this.props.events}
            eventContent={renderEventContent} // custom render function
            eventClick={this.handleEventClick}
            eventAdd={this.handleEventAdd}
            eventChange={this.handleEventChange} // called for drag-n-drop/resize
            eventRemove={this.handleEventRemove}
          />
        </div>
      </div>
    );
  }

  selects(state) {
    // console.log('stated -   -- - - >',state)
    return state.CalenderDateReducer.schedules;
  }

  // handlers for user actions
  // ------------------------------------------------------------------------------------------

  handleDateSelect = (selectInfo) => {
    return;
    var date1 = new Date();
    var date2 = new Date(formatDate(selectInfo.startStr));
    // console.log("datech 1 -", selectInfo.startStr);
    // console.log("datech 2", this.props.events);
    const getFi = this.props.events.filter((e)=>{
      return e.start === selectInfo.startStr
    })
    if(getFi?.length > 0){
      // console.log("getFi",getFi[0].title)
    } else {
      var Difference_In_Time = date2.getTime() - date1.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      if (0 > Difference_In_Days + 1) {
        // console.log(" datech difference correct", Difference_In_Days);
      } else {
        let calendarApi = selectInfo.view.calendar;
        let title = prompt("Please enter Quantity");
        if (title?.match("^[0-9]+$")) {
          calendarApi.unselect(); // clear date selection
          /* If Creating event on exits date then execute this function for remove first events */
          this.props.events.map((data, i) => {
            if (data.start === selectInfo.startStr) {
              this.props.deleteEvent(data.id).catch(() => {
                reportNetworkError();
                // removeInfo.revert();
              });
              this.props.countRemoved(data);
            }
          });
          if (title) {
            calendarApi.addEvent(
              {
                // will render immediately. will call handleEventAdd
                title,
                start: selectInfo.startStr,
                end: selectInfo.endStr,
                allDay: selectInfo.allDay,
              },
              true
            ); // temporary=true, will get overwritten when reducer gives new events
          }
        } else {
          alert("Please enter a valid number");
        }
      }

    }

  };

  handleEventClick = (clickInfo) => {
    // console.log('click info cjs  - - - ', clickInfo.event.title)
    return;
    if(clickInfo.event.title === 'Booked'){
      
    } else{
      if (
        window.confirm(
          `Are you sure you want to delete`
        )
      ) {
        clickInfo.event.remove(); // will render immediately. will call handleEventRemove
      }
    }
  };

  // handlers that initiate reads/writes via the 'action' props
  // ------------------------------------------------------------------------------------------

  handleDates = (rangeInfo) => {
    this.props
      .requestEvents(rangeInfo.startStr, rangeInfo.endStr)
      .catch(reportNetworkError);
  };

  handleEventAdd = (addInfo) => {
    this.props.createEvent(addInfo.event.toPlainObject()).catch(() => {
      reportNetworkError();
      addInfo.revert();
    });
  };

  handleEventChange = (changeInfo) => {
    this.props.updateEvent(changeInfo.event.toPlainObject()).catch(() => {
      reportNetworkError();
      changeInfo.revert();
    });
  };

  handleEventRemove = (removeInfo) => {
    this.props.events.map((date, i) => {
      if (date.id === parseInt(removeInfo.event.id)) {
        this.props.orderRemoved(this.props.events[i]);
      } else {
        // this.props.countRemoved(removeInfo)
      }
    });
    this.props.deleteEvent(removeInfo.event.id).catch(() => {
      reportNetworkError();
      removeInfo.revert();
    });
  };
}

function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  );
}

function reportNetworkError() {
  console.log("This action could not be completed");
}

function mapStateToProps() {
  const getEventArray = createSelector(
    (state) => state.eventsById,
    getHashValues
  );

  return (state) => {
    return {
      events: getEventArray(state),
      weekendsVisible: state.weekendsVisible,
    };
  };
}

export default connect(mapStateToProps, actionCreators)(DemoApp);
