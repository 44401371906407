import React from "react";
import "./notfound.scss";
import { Button } from "@mui/material";

const NotFound404 = () => {
  return (
    <div className="notfound">
      <div className="text-wrapper">
        <div className="title" data-content="404">
          404
        </div>

        <div className="subtitle">Oops, the page you're looking for doesn't exist.</div>
        <br />
        <br />
        <Button variant="contained" className="outdoor-slider-btn">
          <a href="/">Go to homepage</a>
        </Button>
      </div>
    </div>
  );
};

export default NotFound404;
