import * as React from "react";
import { Typography, CardActionArea, Box } from "@mui/material";
import { CustomizedCard, CustomizedCardMedia, CustomizedBox } from "../../../globalstyled/homeStyle";
import { PenIcon } from "../../../globalstyled/iconslist";
import { useHistory } from "react-router-dom";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { postApiCall } from "../../../common/axios";
import Cookies from "js-cookie";
import { API_URL } from "../../../common/defines";

export default function SlideCard({ firstHalf, secondHalf, favouriteChange }) {
  const history = useHistory();
  const champaignPage = () => {
    history.push({ pathname: `/outdoor/${firstHalf.campaignName}`, search: `?id=${firstHalf.campaignId}`, champaginId: 3 });
  };
  const champaignPage2 = () => {
    history.push({ pathname: `/outdoor/${secondHalf.campaignName}`, search: `?id=${secondHalf.campaignId}`, champaginId: 3 });
  };

  const favouriteHandle = async (e, campaignId, favouriteStatus) => {
    e.preventDefault();
    e.stopPropagation();
    const reqBody = {
      userId: Cookies.get("userId"),
      campaignId,
      favouriteStatus,
    };
    try {
      const response = await postApiCall(API_URL + "favourite/addToFavourite", reqBody);
      if (response.status === false) {
      } else if (response.status === true) {
        favouriteChange();
      }
    } catch (error) {
      console.log(`Error in Copy cart ${error}`);
    }
  };

  return (
    <div style={{ marginLeft: "10px", marginRight: "10px" }}>
      <CustomizedCard onClick={champaignPage}>
        <CardActionArea className="homeoutdoorfavouriteWrapeer">
          {Cookies.get("userId") && (
            <>
              {firstHalf?.isfavourite ? (
                <div
                  className="left"
                  onClick={(e) => {
                    favouriteHandle(e, firstHalf?.campaignId, !firstHalf?.isfavourite);
                  }}
                >
                  <FavoriteIcon style={{ color: "#FFD700" }} />
                  {/* <StarsIcon style={{color:"#fff"}}/> */}
                </div>
              ) : (
                <div
                  className="left"
                  onClick={(e) => {
                    favouriteHandle(e, firstHalf?.campaignId, !firstHalf?.isfavourite);
                  }}
                >
                  <FavoriteBorderIcon style={{ color: "#000" }} />
                  {/* <StarsIcon style={{color:"#fff"}}/> */}
                </div>
              )}
            </>
          )}
          <CustomizedBox>
            <CustomizedCardMedia className="outdoor-slider-image-fluid" component="img" src={firstHalf?.campaignImages[0]} alt={firstHalf?.campaignName} />
          </CustomizedBox>
          <Typography className="cardcontent-outdoor title" variant="h5">
            {firstHalf?.campaignName.substring(0, 20)}
            {firstHalf?.campaignName?.length > 20 ? " ..." : ""}
          </Typography>
          <Typography className="cardcontent-outdoor body" variant="body2">
            {firstHalf?.mediaOption}
          </Typography>
          <Box className="cardfooter-wrap">
            <PenIcon className="pen-icon-footer" />
            <p className="card-footer-details">
              ₹ {firstHalf?.minBilling} <span>Min Spend</span>
            </p>
          </Box>
        </CardActionArea>
      </CustomizedCard>
      <div className="second-outdoor-slider">
        <CustomizedCard onClick={champaignPage2}>
          <CardActionArea className="homeoutdoorfavouriteWrapeer">
            {Cookies.get("userId") && (
              <>
                {secondHalf?.isfavourite ? (
                  <div
                    className="left"
                    onClick={(e) => {
                      favouriteHandle(e, secondHalf?.campaignId, !secondHalf?.isfavourite);
                    }}
                  >
                    <FavoriteIcon style={{ color: "#FFD700" }} />
                    {/* <StarsIcon style={{color:"#fff"}}/> */}
                  </div>
                ) : (
                  <div
                    className="left"
                    onClick={(e) => {
                      favouriteHandle(e, secondHalf?.campaignId, !secondHalf?.isfavourite);
                    }}
                  >
                    <FavoriteBorderIcon style={{ color: "#000" }} />
                    {/* <StarsIcon style={{color:"#fff"}}/> */}
                  </div>
                )}
              </>
            )}
            <CustomizedBox>
              <CustomizedCardMedia className="outdoor-slider-image-fluid" component="img" src={secondHalf?.campaignImages[0]} alt={secondHalf?.campaignName} />
            </CustomizedBox>
            <Typography className="cardcontent-outdoor title" variant="h5">
              {secondHalf?.campaignName.substring(0, 20)}
              {secondHalf?.campaignName?.length > 20 ? " ..." : ""}
            </Typography>
            <Typography className="cardcontent-outdoor body" variant="body2">
              {secondHalf?.mediaOption}
            </Typography>
            <Box className="cardfooter-wrap">
              <PenIcon className="pen-icon-footer" />
              <p className="card-footer-details">
                ₹ {secondHalf?.minBilling} <span>Min Spend</span>
              </p>
            </Box>
          </CardActionArea>
        </CustomizedCard>
      </div>
    </div>
  );
}
