import React from "react";
import { InputLabel, MenuItem, FormControl, Select } from "@mui/material";
import { useDispatch } from "react-redux";
const menuitem = [
  { value: 0, title: "New" },
  { value: 1, title: "Under Review" },
  { value: 2, title: "Approved" },
  { value: 3, title: "Scheduled " },
  { value: 4, title: "In Execution" },
  { value: 5, title: "Ended" },
  { value: 6, title: "Closed" },
  { value: 7, title: "Expired" },
];

export default function FilterStatus() {
  const [search, setSearch] = React.useState("");
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setSearch(event.target.value);
    dispatch({ type: "typeTrigger", payload: event.target.value });
  };

  return (
    <FormControl variant="standard" className="head-filter-wrapper">
      <InputLabel id="demo-simple-select-standard-label">Filter by search</InputLabel>
      <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={search} onChange={handleChange} label="Filter by search">
        {menuitem?.map((item, key) => {
          return (
            <MenuItem key={key} value={item.value}>
              {item.title}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
