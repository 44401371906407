import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Grid, Tooltip } from "@mui/material";
import { Check as CheckIcon, Create as CreateIcon } from "@mui/icons-material";
import { putApiCall } from "../../common/axios";
import { API_URL } from "../../common/defines";

function NameComponent({ name }) {
  const inputEl = useRef(null);
  const [editable, setEditable] = useState(false);
  const [nameChanged, setNameChanged] = useState(name.orderName);
  const handleClick = () => {
    setEditable(!editable);
  };
  const nameEditHandler = (e) => {
    setNameChanged(e.target.value);
  };
  const keyCheckHandler = (e) => {
    if (e.key === "Enter") {
      nameChangeApi();
      setEditable(!editable);
    }
  };

  const nameChangeApi = async () => {
    try {
      const reqBody = {
        orderId: name.orderId,
        orderName: nameChanged,
      };
      const paramsData = {
        queryParams: {},
        headers: {},
        isAuthorized: true,
      };
      const response = await putApiCall(API_URL + "dashboard/editOrderName", reqBody, paramsData);
      if (response.status === false) {
      } else if (response.status === true) {
      }
    } catch (error) {
      console.log(`Error in Copy cart ${error}`);
    }
  };

  return (
    <>
      <Grid container className="editable-wrapper" spacing={2}>
        {!editable ? (
          <>
            <Grid className="edit-title" align="left" item xs={10} sm={10} md={10}>
              <Tooltip title="Outdoor Champion" arrow>
                <p className="campaign-title">
                  <span>
                    <Link to={`/dashboard/${name.orderId}`}>{nameChanged}</Link>
                  </span>
                </p>
              </Tooltip>
            </Grid>
            <Grid align="left" item xs={2} sm={2} md={2}>
              <Tooltip title="Edit Name" arrow>
                <CreateIcon className="create-icon-editable" fontSize="small" onClick={handleClick} />
              </Tooltip>
            </Grid>
          </>
        ) : (
          <Grid align="left" item xs={12} sm={10} md={8}>
            <div className="editableContainer">
              <input className="editablebox" ref={inputEl} onChange={nameEditHandler} value={nameChanged} onKeyDown={keyCheckHandler} type="text"></input>
              <div
                className="editableicon"
                onClick={() => {
                  nameChangeApi();
                  setEditable(!editable);
                }}
              >
                <CheckIcon size="small" fontSize="small" />
              </div>
            </div>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default NameComponent;
