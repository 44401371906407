import Cookies from 'js-cookie'

class LocalStorage {
  setItem = (key, value) => {
    localStorage.setItem(key, value);
  };

  getItem = (key) => {
    let item = localStorage.getItem(key);
    return item;
  };

  removeItem = (key) => {
    localStorage.removeItem(key);
  };



  logout = () => {
    localStorage.removeItem("islogin");
    localStorage.removeItem("token");
    Cookies.remove('userId')
    return "/";
  };

  login = (login, token, userId, userName) => {
    localStorage.setItem("islogin",login);
    localStorage.setItem("token",token);
    
    Cookies.set('userId', userId)
    Cookies.set('userName', userName)

    return "/";
  };

  loginStatus = (key) => {
    
    if (localStorage.getItem(key) === "true") return true;
    else return false;
  };
}

export default LocalStorage;
