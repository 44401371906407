import React, { useState, useEffect } from "react";
import { Button, Stack, TextField } from "@mui/material";
import { API_URL } from "../../../../common/defines";
import { useFormik } from "formik";
// import {initialValues, validationSchema } from '../../../../common/validate/signin/Forgot';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { postApiCall } from "../../../../common/axios";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useDispatch } from "react-redux";
import * as yup from "yup";
const Forgot = ({ forgetModalHandle, loginHandle, setIsForgotModalOpen }) => {
  const [otpno, setOtpNo] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: yup.object().shape({
      email: yup.string().required("Email is required"),
      // .email("Email is invalid"),
    }),
    onSubmit: (values) => {
      try {
        const otpDetails = {
          username: values?.email,
        };
        apiMailForOtp(otpDetails);
      } catch (err) {}
      // formik.resetForm();
      // loginHandle();
    },
  });
  const renderErrorMessage = (field) => {
    return formik.touched[field] && <div className="text-error text-err">{formik.errors[field]}</div>;
  };

  const apiMailForOtp = async (reqBody) => {
    const paramsData = {
      queryParams: {},
      headers: {},
      isAuthorized: true,
    };

    try {
      const response = await postApiCall(API_URL + "setting/forgotPassword", reqBody, paramsData);
      if (response.status === false) {
        dispatch({
          typed: "error",
          type: "notificationTrigger",
          payload: `${response.message}`,
        });
      } else if (response.status === true) {
        dispatch({
          type: "notificationTrigger",
          payload: `${response.message}, Please check mail and enter the OTP`,
        });
      }
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    return () => {
      setIsForgotModalOpen(false);
    };
  }, []);

  const apiOtpVerify = async () => {
    const paramsData = {
      queryParams: {},
      headers: {},
      isAuthorized: true,
    };
    const reqBody = {
      otp: otpno,
      username: formik.values.email,
      otpFor: "forgot-password",
      newPassword: newPassword,
    };

    try {
      const response = await postApiCall(API_URL + "verification/verifyOtp", reqBody, paramsData);
      // console.log('response -> ', response)
      if (response.status === false) {
        dispatch({
          type: "notificationTrigger",
          typed: "error",
          payload: `${response.message}`,
        });
      } else if (response.status === true) {
        dispatch({
          type: "notificationTrigger",
          payload: `${response.message}`,
        });
        loginHandle();
        dispatch({ type: "loginmodal" });
      }
    } catch (error) {
      alert(error);
    }
  };

  const clickIcon = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="signup-field-container">
      <Stack className="stack-wrapper back" spacing={6}>
        <ArrowBackIcon onClick={forgetModalHandle} />
      </Stack>
      <div>
        <p className="forgot-txt">Please type in your Email ID, we will send you a OTP to change the password.</p>
        <p className="forgot-txt">
          <span>(*Please check your Inbox/Spam folder for the OTP)</span>
        </p>
        <TextField
          label="Email *"
          variant="standard"
          className={"sl-field"}
          id="email"
          name="email"
          {...formik.getFieldProps("email")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <Button
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                  className="get-otp-btn"
                >
                  Get OTP
                </Button>
              </InputAdornment>
            ),
          }}
        />
        {renderErrorMessage("email")}
        <TextField
          label="OTP *"
          variant="standard"
          type="number"
          className={"sl-field"}
          id="otp"
          name="otp"
          value={otpno}
          onChange={(e) => {
            setOtpNo(e.target.value);
          }}
          onInput={(e) => {
            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 4);
          }}
          // {...formik.getFieldProps('otp')}
        />
        {/* {renderErrorMessage('otp')} */}
        <TextField
          label="Password *"
          variant="standard"
          type={showPassword ? "text" : "password"}
          className={"sl-field"}
          id="password"
          name="password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {!showPassword === true ? (
                  <Visibility onClick={clickIcon} style={{ cursor: "pointer" }} />
                ) : (
                  <VisibilityOffIcon onClick={clickIcon} style={{ cursor: "pointer" }} />
                )}
              </InputAdornment>
            ),
          }}
          value={newPassword}
          onChange={(e) => {
            setNewPassword(e.target.value);
          }}
          // onInp
          // {...formik.getFieldProps('password')}
        />
        {/* {renderErrorMessage('password')} */}
      </div>
      <div className="btn-trig-container">
        <Button variant="contained" onClick={apiOtpVerify}>
          SUBMIT
        </Button>
      </div>
    </div>
  );
};

export default Forgot;
