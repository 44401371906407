import React, { useState, useRef, useEffect } from "react";
import Cookies from "js-cookie";
import { Grid, Button, Box, Modal, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { useParams } from "react-router-dom";
import { TextField } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useDispatch } from "react-redux";
import { API_URL } from "../../common/defines";
import { postApiCall, getApiCall } from "../../common/axios";
import { Grid12All, GridA, GridC } from "../shared/GridWrap";
const Uploads = () => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoadiing] = useState(false);
  const [imgFile, setImgFile] = useState({
    fileName: "",
    extension: "",
    file: "",
  });
  const [fieldLink, setFieldLink] = useState({
    name: "",
    link: "",
  });
  const orderId = useParams().slug;
  const [uploadType, setUploadType] = React.useState("");
  const [uploadedDataList, setUploadedDataList] = useState([]);
  const handleModalOpen = () => setOpenModal(true);
  const handleModalClose = () => setOpenModal(false);

  const imgRerHidden = useRef(null);

  const handleChange = (event) => {
    setUploadType(event.target.value);
  };

  const triggerImgInputHandle = (e) => {
    let fName = e.target.files[0].name.split(".")[0],
      ext = e.target.files[0].name.split(".")[1];
    setImgFile({ fileName: fName, extension: ext, file: e.target.files[0] });
  };
  const uploadFileApi = async (type) => {
    setIsLoadiing(true);
    if (type === "file") {
      const formData = new FormData();
      formData.append("fileFor", "campagins_order");
      formData.append("fileToUpload", imgFile.file);
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      const paramsData = {
        queryParams: {},
        headers,
        isAuthorized: true,
      };
      try {
        const response = await postApiCall(API_URL + "upload/uploadFiles", formData, paramsData);
        if (response.status === false) {
          dispatch({
            type: "notificationTrigger",
            typed: "error",
            payload: `${response.message}`,
          });
          setIsLoadiing(false);
        } else if (response.status === true) {
          orderFileUploadApi(response.data.original_name);
        }
      } catch (error) {
        console.log(`Error in Copy cart ${error}`);
      }
    } else if (type === "link") {
      const reqBody = {
        orderId: orderId,
        userId: Cookies.get("userId"),
        fileType: "2",
        fileName: fieldLink.name,
        file: fieldLink.link,
      };
      try {
        const response = await postApiCall(API_URL + "dashboard/uploadFiles", reqBody);
        if (response.status === false) {
          dispatch({
            typed: "error",
            type: "notificationTrigger",
            payload: `${response.message}`,
          });
          setIsLoadiing(false);
        } else if (response.status === true) {
          handleModalClose();
          dispatch({
            type: "notificationTrigger",
            payload: `${response.message}`,
          });
          uploadedFileListApi();
          setFieldLink({
            name: "",
            link: "",
          });
          setIsLoadiing(false);
        }
      } catch (error) {
        console.log(`Error in Copy cart ${error}`);
      }
    } else {
      setIsLoadiing(false);
      dispatch({
        typed: "error",
        type: "notificationTrigger",
        payload: `Please Select Uploadeeeee type`,
      });
    }
  };

  const orderFileUploadApi = async (file) => {
    const reqBody = {
      orderId: orderId,
      userId: Cookies.get("userId"),
      fileType: "1",
      fileName: imgFile.fileName,
      file: file,
    };
    try {
      const response = await postApiCall(API_URL + "dashboard/uploadFiles", reqBody);
      if (response.status === false) {
        setIsLoadiing(false);
      } else if (response.status === true) {
        handleModalClose();
        dispatch({
          type: "notificationTrigger",
          payload: `${response.message}`,
        });
        uploadedFileListApi();
        setImgFile({
          fileName: "",
          extension: "",
          file: "",
        });
        setIsLoadiing(false);
      }
    } catch (error) {
      console.log(`Error in Copy cart ${error}`);
    }
  };

  useEffect(() => {
    uploadedFileListApi();
  }, []);

  const uploadedFileListApi = async (image) => {
    try {
      const paramsData = {
        queryParams: {
          orderId: orderId,
        },
        headers: {},
        isAuthorized: true,
      };
      const res = await getApiCall(API_URL + "dashboard/getAllUploadedFiles/" + Cookies.get("userId"), paramsData);
      setUploadedDataList(res.uploadFilesData);
    } catch (err) {
      console.log("err", err);
    }
  };

  function downloadFile(filePath) {
    var link = document.createElement("a");
    link.href = filePath;
    link.download = filePath.substr(filePath.lastIndexOf("/") + 1);
    link.click();
  }

  return (
    <div className="upload-wrapper">
      <Button onClick={handleModalOpen}>Upload</Button>
      {uploadedDataList?.length === 0 ? null : (
        <div className="uploadtablesection">
          <Grid container className="table-outdoor-campaign" justifyContent={"space-between"}>
            <GridA size={[4, 4, 2, 3]}>
              <div className="user-name-wrap">
                <p className="user-name">S. No</p>
              </div>
            </GridA>
            <GridA size={[6, 6, 8, 7]}>
              <div className="user-email-wrap">
                <p className="user-email">Name</p>
              </div>
            </GridA>
            <GridA size={[2, 2, 2, 2]}>
              <div className="user-action-wrap">
                <p className="user-action">Action</p>
              </div>
            </GridA>
          </Grid>
          {uploadedDataList?.map((list, i) => {
            return (
              // <li key={i}>
              //   <div>{i}</div>
              //   <div>{list.fileName}</div>
              //   {list.fileType === "Link" ? (
              //     <div>
              //       <a href={`${list.file}`}>link</a>
              //     </div>
              //   ) : (
              //     <div>
              //       <Button onClick={() => {}}>Download</Button>
              //     </div>
              //   )}
              // </li>

              <Grid key={i} container className="table-outdoor-campaign" justifyContent={"space-between"}>
                <GridA size={[4, 4, 2, 3]}>
                  <div className="user-name-wrap">
                    <p className="user-name">{i + 1}</p>
                  </div>
                </GridA>
                <GridA size={[6, 6, 8, 7]}>
                  <div className="user-email-wrap">
                    <p className="user-email">{list.fileName}</p>
                  </div>
                </GridA>
                {/* <Grid item xs={2} sm={2} md={2} xl={2}>
                <div className="user-action-wrap">
                  <p className="user-action">Action</p>
                  </div>
                </Grid> */}
                {list.fileType === "Link" ? (
                  <GridA size={[2, 2, 2, 2]}>
                    <div className="user-action-wrap">
                      <Button
                        onClick={() => {
                          window.open(list.file, "_blank");
                        }}
                      >
                        link
                      </Button>
                    </div>
                  </GridA>
                ) : (
                  <GridA size={[2, 2, 2, 2]}>
                    <div className="user-action-wrap">
                      <Button
                        onClick={() => {
                          window.open(list.file, "_blank");
                        }}
                      >
                        <DownloadIcon />
                      </Button>
                    </div>
                  </GridA>
                )}
              </Grid>
            );
          })}
        </div>
      )}
      <Modal open={openModal} onClose={handleModalClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box className="upload-modal-wrap">
          <GridC clx="upload-container">
            <Grid12All>
              <div className="upload-wrap">
                <h4 className="upload-title">Upload</h4>
              </div>
            </Grid12All>
            <Grid12All>
              <div>
                <FormControl variant="standard" className="upload-form">
                  <InputLabel id="demo-simple-select-standard-label">Select</InputLabel>
                  <Select variant="standard" labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={uploadType} onChange={handleChange} label="Select">
                    <MenuItem value="file">File</MenuItem>
                    <MenuItem value="link">Link</MenuItem>
                  </Select>
                  <h2 className="upload-caption">Please select upload type</h2>
                </FormControl>
              </div>
            </Grid12All>
            {uploadType === "file" ? (
              <Grid12All>
                <div className="file-container">
                  <div className="file-container-btn">
                    <input onChange={triggerImgInputHandle} id="avatar-picker" hidden type="file" multiple accept="image/*" ref={imgRerHidden} />
                    <Button
                      onClick={(e) => {
                        imgRerHidden.current.click();
                      }}
                      className="btn-file-upload"
                      variant="contained"
                    >
                      Upload File <CloudUploadIcon fontSize="small" className="upload-icons" />
                    </Button>
                  </div>
                  <TextField
                    id="file-name"
                    label="File Name"
                    variant="standard"
                    value={imgFile.fileName}
                    onChange={(e) => {
                      setImgFile({ ...imgFile, fileName: e.target.value });
                    }}
                  />
                </div>
              </Grid12All>
            ) : uploadType === "link" ? (
              <Grid12All>
                <div className="link-container">
                  <TextField
                    id="link-name"
                    label="Link Name"
                    variant="standard"
                    value={fieldLink.name}
                    onChange={(e) => {
                      setFieldLink({
                        ...fieldLink,
                        name: e.target.value,
                      });
                    }}
                  />
                  <TextField
                    id="url-link"
                    label="URL or Link"
                    variant="standard"
                    value={fieldLink.link}
                    onChange={(e) => {
                      setFieldLink({
                        ...fieldLink,
                        link: e.target.value,
                      });
                    }}
                  />
                </div>
              </Grid12All>
            ) : null}
            <Grid12All>
              <div className="add-cancel-btn-wrap">
                <Button className="btn-cancel" variant="text" onClick={handleModalClose}>Cancel</Button>
                <Button
                  className="btn-add"
                  variant="text"
                  disabled={isLoading}
                  onClick={() => {
                    uploadFileApi(uploadType);
                  }}
                >
                  Add
                </Button>
              </div>
            </Grid12All>
          </GridC>
        </Box>
      </Modal>
    </div>
  );
};

export default Uploads;
