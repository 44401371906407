import LocalStorage from "../../services/local_storage";
let localstorage = new LocalStorage();
const initialState = {
  login: localstorage.loginStatus('islogin') === true,
  loginModal: false,
  signupModal:false,
  isLoading:false,
};

function loginReducer(state = initialState, action) {
  
  switch (action.type) {
    case "login":
      return { ...state,  login: true };
    case "logout":
      return { ...state, login: false };
    case "loginmodal":
      return { ...state, loginModal: !state.loginModal };
    case "signupmodal":
      return { ...state, signupModal: !state.signupModal };
    case 'isloadingload':
      return{
        ...state, isLoading: !state.isLoading
      }
      case 'isloadingunload':
        return{
          ...state, isLoading: false
        }
    default:
      return state;
  }
}

export default loginReducer;