import React, { useState } from "react";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../../common/defines";
import { postApiCall } from "../../common/axios";
import { Grid, Button } from "@mui/material";
import LocalStorage from "../../services/local_storage";
import {
  WorkOutline as WorkOutlineIcon,
  VideoLabel as VideoLabelIcon,
  ErrorOutline as ErrorOutlineIcon,
  WbIncandescentOutlined as WbIncandescentOutlinedIcon,
} from "@mui/icons-material";
import ExecuteCard from "./ExecuteCard";
import { CustomSlidBoxListing } from "../../globalstyled/homeStyle";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "./itemdesccard.scss";
import moment from "moment";
import { KeyInsight } from "./ItemWrap";
import MapWrapper from "./MapWrapper";
import SliderModal from "../modal/SliderModal";
import { GridA } from "../shared/GridWrap";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import axios from "axios";
import { saveAs } from "file-saver";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

const ItemDescCard = (props) => {
  // console.log("pros - - ->",props)
  const cartData = useSelector((state) => state.cartReducer.data);
  // console.log(cartData)
  const dispatch = useDispatch();
  let localstorage = new LocalStorage();

  const addChampaignToBagHandler = async () => {
    if (localstorage.getItem("islogin") === "true") {
      await copyChampaign();
    } else {
      dispatch({ type: "notificationTrigger", typed: "warning", payload: `Hi Guest, Please Login to access` });
      dispatch({ type: "loginmodal" });
    }
  };
  const isAvalaible = () => {
    if (props.endDate === null) {
      return true;
    }
    if (moment(props.endDate).isSame(moment(new Date()).format("YYYY-MM-DD"))) {
      return false;
    }
    return moment(props.endDate).isBefore();
  };

  const isAvalaibleCam = () => {
    if (cartData?.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const copyChampaign = async () => {
    try {
      const reqBody = {
        userId: Cookies.get("userId"),
        campaginId: props.campaignId,
        quantity: props.quantity,
        campaginPrice: props.price,
      };
      const paramsData = {
        queryParams: {},
        headers: {},
        isAuthorized: true,
      };
      const response = await postApiCall(API_URL + "cart/addToCart", reqBody, paramsData);
      dispatch({ type: "notificationTrigger", payload: `Added Successfully` });
      dispatch({ type: "cartreload" });
      return response;
    } catch (error) {
      console.log(`Error in Copy cart ${error}`);
    }
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const imgDownloadfromCrossDomain = (e, slider) => {
    e.preventDefault();
    e.stopPropagation();
    // saveAs("https://miro.medium.com/max/720/1*8qqYGiWE4OztUnJ_VbzW0g.jpeg", 'image.jpg')
    saveAs(slider, "image.jpg");
    // console.log('slider-- - > ', slider)
    // function download(name, contents, mime_type) {
    //   mime_type = mime_type || "image/jpeg";

    //   let blob = new Blob([contents], { type: mime_type });

    //   let downloadLink = document.createElement("a");
    //   downloadLink.download = name;
    //   downloadLink.href = window.URL.createObjectURL(blob);
    //   // downloadLink.href = slider;
    //   // downloadLink.target = "_blank";
    //   downloadLink.onclick = function (e) {
    //     var that = this;
    //     setTimeout(function () {
    //       window.URL.revokeObjectURL(that.href);
    //     }, 1500);
    //   };

    //   downloadLink.click();
    //   downloadLink.remove();
    // }

    // axios.get(slider,{responseType: 'arraybuffer'})
    //   .then(function (response) {
    //     console.log('slider-->', response)
    //     console.log('slider-->', response.headers['content-type'])
    //     download(slider.split("/").pop().split(".")[0], response.data, response.headers['content-type']);
    //     // return response.blob();
    //   })

    // .then(function (blob) {
    //   console.log('blob - - - > ',blob)
    //   // download(slider.split("/").pop().split(".")[0], blob, blob.type);
    // });
  };

  const favouriteHandle = async (e, campaignId, favouriteStatus) => {
    e.preventDefault();
    e.stopPropagation();

    const reqBody = {
      userId: Cookies.get("userId"),
      campaignId,
      favouriteStatus,
    };
    try {
      const response = await postApiCall(API_URL + "favourite/addToFavourite", reqBody);
      if (response.status === false) {
      } else if (response.status === true) {
        props.favouriteChange()
      }
    } catch (error) {
      console.log(`Error in Copy cart ${error}`);
    }
  };

  return (
    <>
      <SliderModal campaignImages={props.campaignImages} show={show} handleClose={handleClose} />
      <GridA size={[12, 12, 12, 12]} classes="hero-wrap">
        <Grid spacing={2} container className="hero-section">
          <GridA size={[12, 12, 6, 6]}>
            <div className="hero-container">
              <div className="image-container customslider">
                <CustomSlidBoxListing>
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={30}
                    loop={true}
                    pagination={{
                      clickable: true,
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="mySwiper"
                  >
                    {props.campaignImages.map((slider, id) => {
                      return (
                        <SwiperSlide key={id + "00"}>
                          <img className="img-banner" alt={slider} src={slider} onClick={() => setShow(true)} />
                          <FileDownloadRoundedIcon
                            className="imag-download-btn"
                            onClick={(e) => {
                              imgDownloadfromCrossDomain(e, slider);
                            }}
                          />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </CustomSlidBoxListing>
              </div>
              <div className="desc-container">
                <div className="desc-icon">
                  <div className="desction dash">
                    <h4>{props.mediaOption}</h4>
                    <p>media type</p>
                  </div>
                  <div className="icon">
                    <VideoLabelIcon />
                  </div>
                </div>
                <div className="desc-icon">
                  <div className="desction">
                    <h5>{props.lighting === "Yes" ? "Lit" : "Non Lit"}</h5>
                    <p>Lighting</p>
                  </div>
                  <div className="icon">
                    <WbIncandescentOutlinedIcon />
                  </div>
                </div>
                {Cookies.get("userId") &&
                <div className="desc-icon">
                  <div className="favouriteWrapper">
                    {props.isfavourite ? (
                      <button
                        className="addToFavourite"
                        onClick={(e) => {
                          favouriteHandle(e, props.campaignId, !props.isfavourite);
                        }}
                      >
                        <p>Remove To Favourite </p>
                        <span>
                          <FavoriteIcon />
                        </span>
                      </button>
                    ) : (
                      <button
                        className="addToFavourite"
                        onClick={(e) => {
                          favouriteHandle(e, props.campaignId, !props.isfavourite);
                        }}
                      >
                        <p>Add To Favourite</p>
                        <span>
                          <FavoriteBorderIcon />
                        </span>
                      </button>
                    )}
                  </div>
                  <div className="icon"></div>
                </div> }
              </div>
            </div>
          </GridA>
          <GridA size={[12, 12, 6, 6]}>
            <div className="desc-wrap">
              <h6 className="about-title">{props.aboutTitle}</h6>
              <p className="about-desc">{props.aboutDescription}</p>
            </div>
          </GridA>
        </Grid>
        <Grid spacing={2} container className="hero-section2">
          <GridA size={[12, 12, 6, 6]}>
            <KeyInsight
              list={[{ "Used For": props.usedFor }, { "Ad Type": props.mediaType }, { QUANTITY: props.quantity }, { Span: props.span }, { ID: props.campaignUniqueId }]}
            />
          </GridA>
          <GridA size={[12, 12, 6, 6]}>
            <div className="offer-section">
              {isAvalaible() ? null : (
                <div className="offer-info">
                  <ErrorOutlineIcon />
                  <p>Booked till {moment(props.endDate).format("DD-MMMM-YYYY")}.</p>
                </div>
              )}
              {props?.adminBookedMessage?.length > 0 && (
                <div className="offer-info">
                  <ErrorOutlineIcon />
                  {/* {console.log("props.adminBookedMessage",props.adminBookedMessage)} */}
                  <p>{props?.adminBookedMessage}</p>
                </div>
              )}

              <div className="card-wrapper">
                <div className="card-box">
                  <p>Rack Rate</p>
                  <h4 className="strick">
                    <span>₹ {props.price} per day</span>
                  </h4>
                </div>
                <div className="card-box offer">
                  <p>TMA Offer</p>
                  <h5>
                    <span>₹ {props.discountPrice} </span> per day
                  </h5>
                </div>
              </div>
              <div className="card-wrapper">
                <div className="card-box">
                  <h5>
                    Min Billing <span> ₹ {props.minBilling}</span>
                  </h5>
                </div>
                <Button
                  onClick={() => {
                    if (isAvalaibleCam()) {
                      addChampaignToBagHandler();
                    }
                  }}
                  variant="contained"
                  className={`card-box bag ${isAvalaibleCam() ? null : "disabled"} `}
                >
                  <WorkOutlineIcon />
                  <span>Add to Bag</span>
                </Button>
              </div>
            </div>
          </GridA>
        </Grid>
      </GridA>
      <GridA size={[12, 12, 12, 12]}>
        <Grid spacing={2} container className="detailing-section">
          <GridA size={[12, 12, 4, 3]}>
            <ExecuteCard {...props} />
          </GridA>
        </Grid>
      </GridA>
      <GridA size={[12, 12, 12, 12]}>
        <div style={{ paddingBottom: "100px" }}>
          <MapWrapper data={{ lat: props.lattitude, lon: props.longitude }} googleKey={props.googleKey} />
          <div style={{ height: "500px", paddingBottom: "100px" }} id="map"></div>
        </div>
      </GridA>
    </>
  );
};

export default ItemDescCard;
