import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const Alerts = () => {
  const notif = useSelector((state) => state.notificationReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    if (notif.isTrue && notif.type === "error") {
      toast.error(notif.msg, {
        hideProgressBar: true,
        position: toast.POSITION.TOP_CENTER,
        toastId: "success1",
      });
      dispatch({ type: "notificationStop" });
      return true;
    } else if (notif.isTrue && notif.type === "warning") {
      toast.warning(notif.msg, {
        hideProgressBar: true,
        position: toast.POSITION.TOP_CENTER,
        toastId: "success2",
      });
      dispatch({ type: "notificationStop" });
      return true;
    } else if (notif.isTrue) {
      toast.success(notif.msg, {
        hideProgressBar: true,
        position: toast.POSITION.TOP_CENTER,
        toastId: "success3",
      });
      dispatch({ type: "notificationStop" });
    }
  }, [notif]);

  return (
    <div>
      <ToastContainer autoClose={1600} draggableDirection="y" />
    </div>
  );
};

export default Alerts;
