import React from "react";
import Wrapper, { Status } from "./Wrapper";
import MyMapComponent from "./MyMapComponent";

const render = (status) => {
  if (status === Status.LOADING) return <p>Loading...</p>;
  if (status === Status.FAILURE) return <p>Error...</p>;
  return null;
};

const MapWrapper = (props) => {
  
  return (
  
  <Wrapper apiKey={props.googleKey} render={render}>
    <MyMapComponent data={props.data}/>
  </Wrapper>
)};

export default MapWrapper;
