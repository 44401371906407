import React, { useEffect, useState } from "react";
import { Accordion,  Button, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MediaOptionModal from "../modal/dashboard/MediaOptionModal";
import MediaOptionListModal from "../modal/dashboard/MediaOptionListModal";
import MediaOptionDateModal from "../modal/dashboard/MediaOptionDateModal";
import { useSelector } from "react-redux";
import './mediaoptions.scss';
import { GridA, GridC } from "../shared/GridWrap";

export default function SimpleAccordion({ orderItems, totalPrice, dateDaHandler,campaginDetails }) {
  const CalenderDateReducer = useSelector((state) => state.CalenderDateReducer)
  const [count, setCount] = useState(0)
  const [ dateDate, setDateDatad] = useState(null)
  const [openModalList, setOpenModalList] = useState(false);
  const [openModalDate, setOpenModalDate] = useState(false);
  const [list, setList] = useState([]);
  const [expanded, setExpanded] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  // const handleModalOpen = () => setOpenModal(true);
  // const handleChange = (panel) => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false);
  // };
  // function handleDelete(id) {
  // }
  
  const handleModalClose = () => setOpenModal(false);
  function modalHandler(id, il) {
    setList(il);
    setOpenModalList(true);
  }
  function handleModalListClose() {
    setOpenModalList(false);
  }
  function modalDateHandler(id) {
    setOpenModalDate(true);
  }
  function handleModalDateClose() {
    setOpenModalDate(false);
  }
  
  useEffect(()=>{
    setCount(CalenderDateReducer.number_of_counts)
  },[CalenderDateReducer])
  
  const dateDataHanler = (data) => {
    dateDaHandler(data)
    setDateDatad(data)
  }

  return (
    <div>
      <Accordion className="media-option-wrapper" expanded={expanded}>
        <AccordionSummary
          className="accordion-summary"
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <GridC clx="accordion-head-container">
            <GridA size={[10,10,10,10]}>
              <Typography className='formedia-options'>Media Options</Typography>
            </GridA>
            <GridA size={[2,2,2,2]}>
              <div className={`accordion-toggle-icon ${expanded ? "rotate" : ""}`}>
                <KeyboardArrowDownIcon
                  onClick={() => {
                    setExpanded(!expanded);
                  }}
                />
              </div>
            </GridA>
          </GridC>
          <MediaOptionModal
            openModal={openModal}
            handleModalClose={handleModalClose}
          />
        </AccordionSummary>
        {orderItems?.map((items) => {
          let il = {
            id:  items.campaginId,
            "Card Rate": items.card_rate,
            "Discounted Rate":  items.offer_rate,
            "Sub Total": items.sub_total,
            GST: items.gst,
            "Total (Incl. Tax)": items.totalAmount,
          };
          return (
            <AccordionDetails key={items.campaginId} className="">
              <GridC clx="accordion-body-container">
                
                <GridA size={[1,1,1,1]}></GridA>
                <GridA size={[11,11,11,11]}>
                  <p className="details-items">{items.title}</p>
                  <p className="details-items">Quantity : { count > 0 ?  count : items.quantity}</p>
                  {/* <p className="details-items">Quantity : { count > 0 ?  count : 0}</p> */}
                  {/* <p className="details-items">
                    
                    Total Price : { count > 0 ? (parseInt(items.offer_rate) * count + parseInt(items.quantity) ) + ((parseInt(items.offer_rate) * count + parseInt(items.quantity)) * 0.18) : 0 }
                  </p> */}
                  <p className="details-items">
                    
                    Price : { items.totalAmount }
                  </p>
                  <div className="footer-button-container">
                    <button className="footer-button">
                      <EditIcon
                        onClick={() => modalHandler(items.campaginId, il)}
                      />
                    </button>
                    {/* <button className="footer-button footer">
                      <DeleteIcon
                        onClick={() => handleDelete(items.campaginId)}
                      />
                    </button> */}
                  </div>
                </GridA>
              </GridC>
              <hr />
            </AccordionDetails>
          );
        })}
        {orderItems && 
        <div>
          <div className="accordition">
            {/* <button className="footer-button"> */}
              {/* <Tooltip title="" placement="top-end" arrow> */}
                <Button onClick={() => modalDateHandler(121)} className="request-button" variant="contained">
                  <EditIcon/> Select Date
                </Button>
              {/* </Tooltip> */}
            {/* </button> */}
          </div>
          
          {dateDate && <div className="edit__date__details">
            <p className="title"><span>Add Campaign</span></p>
            <p><span>start Date: </span>{dateDate.start} </p>
            <p><span>end date: </span>{dateDate.end} </p>
            <p><span>Days: </span>{dateDate.DAYS} </p>
            <p><span>Total Price: </span>{dateDate.DAYS * totalPrice} </p>
          </div>
          }
        </div>
      }
      </Accordion>

      <MediaOptionDateModal
        campaginDetails={campaginDetails}
        item={list}
        openModalList={openModalDate}
        handleModalListClose={handleModalDateClose}
        dateDate={dateDate} 
        dateDataHanler={dateDataHanler}
      />
      <MediaOptionListModal
        item={list}
        openModalList={openModalList}
        handleModalListClose={handleModalListClose}
      />
    </div>
  );
}
