import React from "react";
import { Grid, Button } from "@mui/material";
import {
  Map as MapIcon,
  TableRows as TableRowsIcon,
} from "@mui/icons-material";
import { GridA } from "../shared/GridWrap";
export const ViewLM = ({ handleView, view }) => {
  function viewHandle() {
    handleView();
  }
  const IcoN = ({ Ico, title, classes }) =>
      <>
        <Ico className={classes} /> {title}
      </>
      
  const ViewI = ({view}) => 
    <>
        {view ? (
            <>
            <IcoN Ico={MapIcon} classes="head-icon" title="Map" />
            </>
        ) : (
            <>
            <IcoN Ico={TableRowsIcon} classes="head-icon" title="List" />
            </>
        )}
    </>
    
  return (
    <>
      <GridA size={[12,12,2,2]} classes="title-map-wrap">
        <p className="page-title">Outdoor</p>
        <div className="map-wrap">
          <Button onClick={viewHandle}>
            <ViewI view={view}/>
          </Button>
        </div>
      </GridA>
    </>
  );
};
