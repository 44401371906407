const initialState = {
  loading: true,
  data: [],
  error: false,
};

function outdoorReducer(state = initialState, action) {
  switch (action.type) {
    case "FETCH_OUTDOOR":
      return {
        loading: true,
        data: "Fetching",
        error: false,
      };
    case "FETCH_OUTDOOR_SUCCESS":
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case "FETCH_OUTDOOR_FAIL":
      return {
        ...state,
        loading: false,
        error: true,
        data: action.payload,
      };
    default:
      return state;
  }
}

export default outdoorReducer;
