import React, { useState,useEffect } from "react";
import { useParams  } from "react-router-dom";
import { API_URL } from "../../common/defines";
import { getApiCall} from "../../common/axios";
import { Grid, Button, Menu, MenuItem } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MediaOptions from "./MediaOptions";
import SaveIcon from "@mui/icons-material/Save";
import Calender from "./Calender";
import RequestApprovalModal from "../modal/dashboard/RequestApprovalModal";
import Cookies from 'js-cookie'
import { useDispatch,useSelector } from "react-redux";
import { postApiCall } from "../../common/axios";
import moment from "moment";
import { GridA, GridC } from "../shared/GridWrap";


const options = ["Email", "Download Excel", "Download PPt"];
let a = document.createElement("a");

const Scheduler = () => {
  const CalenderDateReducer = useSelector((state) => state.CalenderDateReducer)
  
  const dispatch = useDispatch();
  const [ campaginDetails, setCampaginDetails]= useState()
  const [isLoading, setIsLoading] = useState(false);
  const [dateDa, setDateDa] = useState(null)
  const par = useParams().slug;
  Cookies.set('orderId', par)
  useEffect(()=>{
    getApiDetails();
  },[])

  const dateDaHandler = (data) => {
    // const le = enumerateDaysBetweenDates(data.start, data.end)
    // const fle = [data.start, ...le, data.end].map((date)=>{

    // })
    // console.log(" data - -- - -- --- >",data, fle)
    setDateDa(data);
  }

  var enumerateDaysBetweenDates = function(startDate, endDate) {
    var dates = [];
    var currDate = moment(startDate).startOf('day');
    var lastDate = moment(endDate).startOf('day');
    while(currDate.add(1, 'days').diff(lastDate) < 0) {
      // console.log("it is mement - - -- >",moment(currDate.toDate()).format("YYYY-MM-DD"));
      dates.push(moment(currDate.clone().toDate()).format("YYYY-MM-DD"));
    }
    return dates;
  };

  const getApiDetails = async () => {
    setIsLoading(true);
    const paramsData = {
      queryParams: { orderId: par },
      headers: {},
      isAuthorized: true,
    };
    const response = await getApiCall(API_URL + "dashboard/getOrderDetails", paramsData);
    dispatch({
      type: "ordercreate",
      payload: response.orderData.dateDetails,
    });
    dispatch({
      type: "CAMPAIGNDATATRIGGER",
      payload: response.orderData.orderName,
    });
    setCampaginDetails(response.orderData);
    setIsLoading(false);
    return response;
  };
useEffect(()=>{
  // orderDateApi()
},[CalenderDateReducer])

  const [Planningmode, setPlanningMode] = React.useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const handleModalOpen = () => setOpenModal(true);
  const handleModalClose = () => setOpenModal(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (event) => {
    setPlanningMode(event.target.value);
  };


  const emailClickHandler = async() => {
    try{
      const paramsData = {
        queryParams: {
          orderId: par,
          userId:Cookies.get('userId'),
          downloadType:'email'
        },
        headers: {},
        isAuthorized: true,
      };
      const response = await getApiCall(API_URL + "dashboard/dashboardActions", paramsData);
      dispatch({
        type: "notificationTrigger",
        payload: `${response.message}`,
      });
      // console.log('resposne => ',response.message)
    }catch(err){
      console.log('err', err)
    }
  }

  const excelClickHandler = async() => {
    try{
      const paramsData = {
        queryParams: {
          orderId: par,
          userId:Cookies.get('userId'),
          downloadType:'excel'
        },
        headers: {},
        isAuthorized: true,
      };
      const response = await getApiCall(API_URL + "dashboard/dashboardActions", paramsData);
      if(response.code === 200){
        dispatch({
          type: "notificationTrigger",
          // payload: `${response.message} Successfully`,
          payload: 'Download Successfully'
        });
        a.href = response.data;
        a.target="_blank"
        a.click();
      } else {
        dispatch({
          type: "notificationTrigger",
          typed:'error',
          // payload: `${response.message} Successfully`,
          payload: response.message
        });
      }
      // console.log('resposne => ',response.message)
    }catch(err){
      console.log('err', err)
    }
  }

  const pptClickHandler = async() => {
    try{
      const paramsData = {
        queryParams: {
          orderId: par,
          userId:Cookies.get('userId'),
          downloadType:'ppt'
        },
        headers: {},
        isAuthorized: true,
      };
      const response = await getApiCall(API_URL + "dashboard/dashboardActions", paramsData);
      if(response.code === 200){
        dispatch({
          type: "notificationTrigger",
          payload: `Download Successfully`,
        });
        a.href = response.data;
        a.target="_blank"
        a.click();
      }else{
        dispatch({
          type: "notificationTrigger",
          typed:'error',
          // payload: `${response.message} Successfully`,
          payload: response.message
        });
      }
      // console.log('resposne => ',response.message)
    }catch(err){
      console.log('err', err)
    }
  }

  const orderApproveApi = async() => {
    const reqBody = {
      "orderId":par,
      "status":"1"
  }
    try {
      const response = await postApiCall(API_URL + "dashboard/requestApproval", reqBody);
      if (response.status === false) {
      } else if (response.status === true) {
        dispatch({
          type: "notificationTrigger",
          payload: `${response.message}`,
        });
        
        window.location.reload()
      }
    } catch (error) {
      console.log(`Error in Copy cart ${error}`);
    }
  }

  // const counttotalQuantity = () => {
  //   let total = 0;
  //   CalenderDateReducer?.schedules?.map((item)=>{
  //     total += parseInt(item.total_quantity)
  //   })
  //   return total
  // }
function countday(){
    let SDATE = moment(moment(CalenderDateReducer.schedules.date).format("Y-MM-D")), EDATE = moment(moment(CalenderDateReducer.schedules.end_date).format("Y-MM-D"))
    let DAYS = EDATE.diff(SDATE, 'days') + 1
    return DAYS
}
  const orderDateApi = async() => {
    // console.log('CalenderDateReducer.schedules',CalenderDateReducer.schedules)
    const reqBody = {
      "orderId":Cookies.get('orderId'),
      "userId":Cookies.get('userId'),
      "schedules":[CalenderDateReducer.schedules],//{date: "2022-06-29", end_date: "2022-07-05", total_quantity: "1"}
      "totalQuantity" : 1,
      totalDays:countday(),
  }
    try {
      const response = await postApiCall(API_URL + "dashboard/saveDashboardOrder", reqBody);
      if (response.status === false) {
      } else if (response.status === true) {
        // window.location.reload();
        // console.log('response --- ', response)
        dispatch({
          type: "notificationTrigger",
          payload: `${response.message}`,
        });
      }
    } catch (error) {
      console.log(`Error in Copy cart ${error}`);
    }
  }

  const MenuI = ({title,callback}) => {
    return(
    <MenuItem
      onClick={()=>{
        handleClose()
        callback()
      }}
    >
      {title}
    </MenuItem>
    )
  }

  return (
    <>
      <GridC clx="scheduler-head">
        <GridA size={[6,4,2,2]} classes="schedular-container">
          <div className="scheduler-mode">
            <FormControl
              variant="standard"
              sx={{ m: 1, minWidth: 120, marginLeft: 0 }}
            >
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={Planningmode}
                onChange={handleChange}
                label="planning mode"
              >
                <MenuItem value={10}>Planning Mode</MenuItem>
              </Select>
            </FormControl>
          </div>
        </GridA>
        <GridA size={[6,4,3,3]}>
          <div>
            <p className="tab-price">Total Price : {campaginDetails?.orderTotal}</p>
            {console.log('coma dtails- -- - >',campaginDetails)}
            <p className="tab-price">{campaginDetails?.dateDetails[0].startDate} to {campaginDetails?.dateDetails[0].endDate}</p>
          </div>
        </GridA>
        <GridA size={[6,4,2,2]} classes="tab-status-container">
          <div>
            <p className="tab-status">Status : {campaginDetails?.orderStatus}</p>
          </div>
        </GridA>
        <GridA size={[6,4,3,3]} classes="request-button-container">
          <div>
          {(campaginDetails?.orderStatus === 'New') ? <Button
              className="request-button"
              variant="contained"
              onClick={()=>{
                orderApproveApi()
                handleModalOpen()
              }}
            >
              Request Approval
            </Button>  : null }
            
          </div>
        </GridA>
        <GridA size={[12,8,2,2]} classes="action-save-container">
          <div className="action-save-wrapper">
            <Button
              className="action-btn"
              variant="contained"
              onClick={handleClick}
            >
              Actions
            </Button>
            <Button className="save-btn" variant="contained" onClick={()=>{orderDateApi();}}>
              <SaveIcon />
              Save
            </Button>
          </div>
        </GridA>
      </GridC>
      <Grid container spacing={2} className="calender-media-wrapper">
        <GridA size={[12,12,4,3]}>
          <MediaOptions campaginDetails={campaginDetails} dateDaHandler={dateDaHandler} totalPrice={campaginDetails?.orderItems[0]?.totalAmount} orderItems={campaginDetails?.orderItems}/>
          {/* {console.log('compaing -- - -- details - - >',campaginDetails)} */}
        </GridA>
        <GridA size={[12,12,8,9]}>
          <Calender />
        </GridA>
      </Grid>

      {/* Menu Actions Button */}
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "20ch",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuI title="Email" callback={emailClickHandler}/>
        <MenuI title="Download excel" callback={excelClickHandler}/>
        <MenuI title="Download ppt" callback={pptClickHandler}/>
        
      </Menu>

      {/* Modal - Reques Approval*/}
      <RequestApprovalModal
        openModal={openModal}
        handleModalClose={handleModalClose}
      />
    </>
  );
};

export default Scheduler;
