import React from "react";
import { Box, TableSortLabel, Grid } from "@mui/material";
import { Error as ErrorIcon } from "@mui/icons-material";
import { CheckGrid } from "../../globalstyled/dashboard";
import SearchField from "./SearchField";
import FilterStatus from "./FilterStatus";

const DashboardTableHead = () => (
  <Grid container className="table-head-wrapper" alignItems={"baseline"}>
    <Grid item xs={6} sm={6} md={5}>
      <Grid container>
        <Grid item xs={12} sm={11} md={10}>
          <Box className="searchfield-wrapper">
            <SearchField />
          </Box>
        </Grid>
        <Grid item sm={1} md={10}></Grid>
      </Grid>
    </Grid>
    <Grid item xs={2} sm={2} md={2}>
      <Box className="head-price-title" style={{justifyContent:'start'}}>
        <p className="text-center">ID</p> 
      </Box>
    </Grid>
    <CheckGrid item xs={2} sm={2} md={2}>
      <TableSortLabel className="filterwrapper">
        <FilterStatus />
      </TableSortLabel>
    </CheckGrid>
    <Grid item xs={2} sm={2} md={1}>
      <Box className="head-price-title">
        <p className="text-center">Total Price</p> <ErrorIcon fontSize="" />
      </Box>
    </Grid>
    <Grid item xs={2} sm={2} md={2}>
      <Box className="head-price-title">
        <p className="text-center">Actions</p>
      </Box>
    </Grid>
  </Grid>
);

export default DashboardTableHead;
