import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Checkbox,
} from "@mui/material";
import { mapdata } from "../../data";
import DeleteIcon from "@mui/icons-material/Delete";
import MapTableHead from "./MapTableHead";
import { Link } from "react-router-dom";
export default function MapTable() {
  const [rows, setRows] = useState(mapdata);
  const [selected, setSelected] = React.useState([]);
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;
  return (
    <Paper className="paper-table-wrapper">
      <TableContainer>
        <Table
          className="table-wrapper"
          aria-labelledby="tableTitle"
          size={"small"}
        >
          <MapTableHead
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={rows.length}
          />
          <TableBody>
            {rows.map((row, index) => {
              const isItemSelected = isSelected(row.name);
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow
                  hover
                  onClick={(event) => handleClick(event, row.name)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={index}
                  selected={isItemSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      size="small"
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </TableCell>
                  <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    className="table-body-title"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <Link to={`/outdoor/${row.name}`}>{row.name}</Link>
                    <br />
                    <div className="d-flex">
                      <p style={{ fontSize: "10px", margin: "0" }}>
                        <span style={{ fontWeight: "bold", fontSize: "12px" }}>
                          Landmark:
                        </span>
                        AT BANDRA SAGA, NATIONAL LIBRARY, OPP. BANDRA LAKE
                        (M/T), Traffic
                      </p>
                      <DeleteIcon className="ml-2" />
                    </div>
                    <p style={{ fontWeight: "bold", fontSize: "12px" }}>
                      Rate: ₹ 15,000
                    </p>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
