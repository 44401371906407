import React, { useEffect, useState } from "react";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import Moment from "moment";
import { useSelector } from "react-redux";

import { extendMoment } from "moment-range";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import Tooltip from "@mui/material/Tooltip";

const DatePickerRange = ({ setDayCount, setDateDataCart }) => {
  const moment = extendMoment(Moment);
  const cartData = useSelector((state) => state.cartReducer.data);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [dateDate, setDateData] = useState(null);
  
  const [bookingUp, setBookingUP] = useState([]);
  const [bookings, setBookings] = useState([]);
  
  useEffect(() => {
    if(cartData?.length){
      // const filetD = cartData[0]?.bookingDates?.map((b) => {
      //   return {
      //     start: moment(new Date(b?.startDate?.replace("-", ","))),
      //     end: moment(new Date(b?.endDate?.replace("-", ","))),
      //   };
      // });
      const newFiletD = [{
        start: moment(new Date(cartData[0]?.startDate?.replace("-", ","))),
        end: moment(new Date(cartData[0]?.endDate?.replace("-", ","))),
      }]
      setBookings(newFiletD);
      setBookingUP(newFiletD);
    }
  }, [cartData]);

  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    if (startDate !== null) {
      for (let booked of bookings.reverse()) {
        if (moment(startDate.format("Y-MM-D")).diff(moment(booked.start).format("Y-MM-D"), "days") < 0) {
          let bookedFilter = bookings;
          bookedFilter = bookedFilter.map((book) => {
            if (moment(book.start).format("Y-MM-D") === moment(booked.start).format("Y-MM-D")) {
              return {
                start: book.start,
              };
            }
            return book;
          });
          setBookings(bookedFilter);
          break;
        }
      }
    }


    if (startDate != null && endDate != null) {
      let SDATE = moment(startDate.format("Y-MM-D")),
        EDATE = moment(endDate.format("Y-MM-D"));
      let DAYS = EDATE.diff(SDATE, "days") + 1;
      // console.log("date range", DAYS);
      setDayCount(DAYS);
      setDateData({
        start: startDate.format("Y-MM-D"),
        end: endDate.format("Y-MM-D"),
      });
      setDateDataCart({
        start: startDate.format("Y-MM-D"),
        end: endDate.format("Y-MM-D"),
      });
    }
  };

  const isBlocked = (date) => {
    date = date.set("hour", "00").set("minute", "00");
    let bookedRanges = [];
    let blocked;
    bookings.map((booking) => {
      bookedRanges = [...bookedRanges, moment.range(booking.start, booking.end)];
    });
    // let newBoked = {
    //   start: strReplace(startFiltDate),
    //   end: strReplace(endFiltDate),
    // };
    // console.log("bookeRandge -- - ", bookedRanges)
    blocked = bookedRanges.find((range) => {
      return range.contains(date);
    });
    return blocked;
  };

  const resetHandle = () => {
    setStartDate(null);
    setEndDate(null);
    setBookings(bookingUp);
  };

  return (
    <div className="datepickercontainer">
      <DateRangePicker
        isDayBlocked={isBlocked}
        startDateId="tata-start-date"
        startDate={startDate}
        endDate={endDate}
        endDateId="tata-end-date"
        onDatesChange={handleDatesChange}
        focusedInput={focusedInput}
        isOutsideRange={(day) => {
          let date = new Date();
          return moment(date.setDate(date.getDate() - 1)).diff(day) >= 1;
        }}
        // maxDate={moment(new Date())}
        onFocusChange={(focusedInput) => {
          setFocusedInput(focusedInput);
        }}
      />

      <div className="datereset">
        <Tooltip title="Clear Date" placement="top-end" arrow>
          <EventBusyIcon onClick={resetHandle} />
        </Tooltip>
      </div>
    </div>
  );
};

export default DatePickerRange;
