import React, { useState } from "react";
import Cookies from "js-cookie";
import { API_URL } from "../../../../common/defines";
import { Button, Stack, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import GoogleBtn from "../common/GoogleBtn";
import { postApiCall, getApiCall } from "../../../../common/axios";
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  initialValues,
  validationSchema,
} from "../../../../common/validate/signin/Index";
import LocalStorage from "../../../../services/local_storage";
let acknoledgeMsg = "";
const LogIn = ({ signUpModal, loginHandle, forgetModalHandle }) => {
  let localstorage = new LocalStorage();
  const dispatch = useDispatch();
  const [showMsg, setShowMsg] = useState(null);
  const [showPassword , setShowPassword] = useState(false);
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      try {
        const loginDetails = {
          userEmail: values?.email,
          userPassword: values?.password,
        };
        apiLogin(loginDetails);
      } catch (err) { }
      formik.resetForm();
    },
  });
  const handlechanged = (e) => {
    if (e.key === "Enter") {
      formik.handleSubmit();
    }
  };
  const clickIcon =()=>{
    setShowPassword(!showPassword)
  }
  const apiLogin = async (reqBody) => {
    const paramsData = {
      queryParams: {},
      headers: {},
      isAuthorized: true,
    };
    try {
      const response = await postApiCall(
        API_URL + "authentication/login",
        reqBody,
        paramsData
      );
      // console.log('response -> ', response)
      if (response.status === false) {
        dispatch({
          type: "notificationTrigger",
          typed: "error",
          payload: `${response.message}`,
        });
      } else if (response.status === true) {
        localstorage.login(
          true,
          response.data.login_token,
          response.data.userId,
          response.data.userName,
        );
        // localstorage.setItem("userId", response.data.data.userId);
        dispatch({ type: "login" });
        dispatch({ type: "isloadingload" });
        loginHandle();
        apiGetProfile();
        // setTimeout(()=>{
        //   window.location.reload();
        // },1000);
      }
    } catch (error) {
      alert(error);
    }
  };
  const apiGetProfile = async () => {
    try {
      const paramsData = {
        queryParams: {},
        headers: {},
        isAuthorized: true,
      };
      const res = await getApiCall(
        API_URL + "profile/profileDetail/" + Cookies.get("userId"),
        paramsData
      );
      dispatch({
        type: "profile_details",
        payload: res.data,
      });
      localstorage.setItem("avatarImg", res.data.userImage);
      dispatch({
        type: "notificationTrigger",
        payload: `Hi, ${res.data.userName}`,
      });
    } catch (err) {
      console.log("err", err);
    }
  };
  // const apiSetProfile = (token) => {
  //   const headers = {
  //     Authorization: token,
  //   };
  //   axios
  //     .get(`${API_URL}profile/profileDetail/${Cookies.get('userId')}`, { headers })
  //     .then((response) => {
  //       dispatch(fetchProfileSuccess(response.data.data));
  //       if (response.data.status === false) {
  //       } else if (response.data.status === true) {
  //       }
  //     })
  //     .catch((error) => {});
  // };
  const renderErrorMessage = (field) => {
    return (
      formik.touched[field] && (
        <div className="text-error text-err">{formik.errors[field]}</div>
      )
    );
  };

  return (
    <div className="signup-field-container">
      <Stack sx={{ width: "100%" }} spacing={2}>
        {showMsg && acknoledgeMsg}
      </Stack>
      <Stack className="stack-wrapper" spacing={6}>
        <Button variant="text" onClick={signUpModal}>
          Sign Up
        </Button>
      </Stack>
      <div>
        <TextField
          label="Email *"
          variant="standard"
          id="email"
          name="email"
          {...formik.getFieldProps("email")}
          className={"sl-field"}
        />
        {renderErrorMessage("email")}
        <TextField
          label="Password *"
          variant="standard"
          // type="password"
          type={showPassword ? "text" : "password"}
          id="password"
          name="password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {!showPassword ===true ? <Visibility onClick={clickIcon} style={{cursor:"pointer"}} /> : <VisibilityOffIcon onClick={clickIcon} style={{cursor:"pointer"}} />}
              </InputAdornment>
            ),
          }}
          onKeyDown={handlechanged}
          {...formik.getFieldProps("password")}
          className={"sl-field"}
        />
        {renderErrorMessage("password")}
      </div>
      <div className="btn-trig-container">
        <Button
          variant="contained"
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          LOGIN
        </Button>
      </div>
      <div className="btn-forget-container">
        <Button
          variant="text"
          className="txt-forget-pass"
          onClick={forgetModalHandle}
        >
          Forgot Password?
        </Button>
      </div>
      <GoogleBtn loginHandle={loginHandle} type={"login"} title={"Login"} />
    </div>
  );
};
export default LogIn;