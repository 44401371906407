import React, { useState, useEffect } from "react";
import { Box, Paper, Checkbox, Grid } from "@mui/material";
import Cookies from "js-cookie";
import { API_URL } from "../../common/defines";
import { getApiCall } from "../../common/axios";
import "./dashboard.scss";
import { CheckGrid } from "../../globalstyled/dashboard";
import DashboardTableHead from "./DashboardTableHead";
import NameComponent from "./NameComponent";
import OptionList from "./OptionList";
import { useDispatch, useSelector } from "react-redux";
import { goTop } from "../../components/common/gFun/GFun";
import Loader from "../../components/common/Loader";
let a = 0;
const DashboardTable = () => {
  const [rows, setRows] = useState([]);
  const [selected, setSelected] = useState([]);
  const [isFilter, setisFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteCampaign, setDeleteCampaign] = useState(false);
  const [showButton, setShowButton] = useState({
    delete: false,
    merge: false,
  });
  const dispatch = useDispatch();
  const searchDashboardbyFilter = useSelector((state) => state.dashboardReducer);

  useEffect(() => {
    return () => {
      a = 0;
    };
  }, []);

  useEffect(() => {
    if (a > 0) {
      getFilterApiDetails();
    }
    a++;
  }, [searchDashboardbyFilter]);

  useEffect(() => {
    goTop();
    getApiDetails();
    if (deleteCampaign === true) {
      dispatch({ type: "cartreload" });
      setDeleteCampaign(false);
    }
  }, [deleteCampaign]);

  const getApiDetails = async () => {
    setIsLoading(true);
    const paramsData = {
      queryParams: { userId: Cookies.get("userId") },
      headers: {},
      isAuthorized: true,
    };
    const response = await getApiCall(API_URL + "dashboard/getDashboardData", paramsData);
    setRows(response.orderData);
    setIsLoading(false);
    return response;
  };

  const getFilterApiDetails = async () => {
    setisFilter(true);
    const paramsData = {
      queryParams: { userId: Cookies.get("userId"), orderName: searchDashboardbyFilter.title, orderStatus: searchDashboardbyFilter.type, pageNo: 1, limit: 10 },
      headers: {},
      isAuthorized: true,
    };
    const response = await getApiCall(API_URL + "search/filterOrder", paramsData);
    setRows(response.filterData);
  };

  return (
    <>
      {!isLoading ? (
        rows?.length === 0 && isFilter === false ? (
          <div className="row">
            <div className="col-md-12 dashboard-empty-section">
              <h4>
                <center>Dashboard is Empty</center>
              </h4>
            </div>
          </div>
        ) : (
          <>
            <Paper className="paper-table-wrapper">
              {/*    Table Head  */}
              <DashboardTableHead/>
              {/*   Table Body  */}
              {isFilter === true && rows?.length === 0 ? (
                <div>
                  <h3>no result found</h3>
                </div>
              ) : (
                <Grid container>
                  {rows?.map((row, index) => {
                    return (
                      <Grid container alignItems={"baseline"} key={row.orderName + row.orderId}>
                        <Grid item xs={6} sm={6} md={5}>
                          <Box className="searchfield-wrapper">
                            <NameComponent name={row} />
                          </Box>
                        </Grid>
                        <Grid align="left" item xs={2} sm={2} md={2}>
                          <p className="text-left" style={{overflow:"hidden"}}>{row.campaignUniqueId}</p>
                        </Grid>
                        <CheckGrid item xs={2} sm={2} md={2}>
                          <p className="text-left">{row.orderStatus}</p>
                        </CheckGrid>
                        <Grid align="left" item xs={2} sm={2} md={1}>
                          <p className="text-center">{row.orderTotal}</p>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2}>
                          <OptionList setDeleteCampaign={setDeleteCampaign} id={row.orderId}></OptionList>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              )}
            </Paper>
            {rows?.length <= 6 ? rows?.length <= 3 ? <div className="tma-max-height"></div> : <div className="tma-normal-height"></div> : <div className="tma-smalll-height"></div>}
          </>
        )
      ) : (
        <Loader />
      )}
    </>
  );
};

export default DashboardTable;
