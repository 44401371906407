import * as React from "react";
import { CardActionArea } from "@mui/material";
import { CustomizedHeroCard, CustomizedHeroCardMedia, CustomizedHeroBox } from "../../../globalstyled/homeStyle";

export default function HeroSliderCard({ imgSrc, name }) {
  return (
    <div>
      <CustomizedHeroCard>
        <CardActionArea>
          <CustomizedHeroBox>
            <CustomizedHeroCardMedia component="img" src={imgSrc} alt={name} />
          </CustomizedHeroBox>
        </CardActionArea>
      </CustomizedHeroCard>
    </div>
  );
}
