import React, { useState, useEffect } from "react";
import { API_URL } from "../../common/defines";
import { getApiCall } from "../../common//axios";
import { Grid } from "@mui/material";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import { useWindowSize, funs } from "./slidercard/SwiperBreakpoints";
import SlideCard from "./slidercard/HeroSliderCard";
import { CustomHeroSlidBox, HeroGrid } from "../../globalstyled/homeStyle";
const Hero = () => {
  const [heroData, setHeroData] = useState([{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }]);
  const size = useWindowSize();
  useEffect(() => {
    apiHeroSlider();
  }, []);
  const apiHeroSlider = async () => {
    try {
      const res = await getApiCall(API_URL + "home/banner");
      setHeroData(res.bannerdata);
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <HeroGrid>
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <Grid align="center" item xs={12} sm={12} md={12} lg={12}>
            <CustomHeroSlidBox className="outdoor-swipper-slider">
              <Swiper
                slidesPerView={funs(size.width, "topslider")}
                spaceBetween={20}
                slidesPerGroup={1}
                loop={true}
                autoplay={{ delay: 1200 }}
                loopFillGroupWithBlank={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
              >
                {heroData?.map((slider, i) => {
                  return (
                    <SwiperSlide className="hi there ow" key={slider.id}>
                      <SlideCard imgSrc={slider.image} name={slider.name} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </CustomHeroSlidBox>
          </Grid>
        </Grid>
      </Grid>
    </HeroGrid>
  );
};
export default Hero;
