import React, { useState, useEffect } from "react";
export function goTop(){
  setTimeout(()=>{
    window.scrollTo({
      top: 0, 
      left: 0
    });
  },300)
  }

  