import React, { useState, useEffect } from "react";
import { API_URL } from "../../common/defines";
import { getApiCall } from "../../common/axios";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { useWindowSize, funs } from "./slidercard/SwiperBreakpoints";
import SliderCard from "./slidercard/ClientSliderCard";
import { CustomSlidBoxClient } from "../../globalstyled/homeStyle";
function ClientSlider() {
  const [heroData, setHeroData] = useState([]);
  const size = useWindowSize();

  useEffect(() => {
    apiClientSlider();
  }, []);
  const apiClientSlider = async () => {
    try {
      const queryParams = {
        pageNo: 1,
        limit: 10,
      };
      const paramsData = { queryParams };
      const res = await getApiCall(API_URL + "home/review", paramsData);
      setHeroData(res.reviewData);
    } catch (err) {
      console.log("err", err);
    }
  };
  return (
    <CustomSlidBoxClient>
      <Swiper
        slidesPerView={funs(size.width, "clientslider")}
        spaceBetween={20}
        slidesPerGroup={1}
        loop={true}
        loopFillGroupWithBlank={true}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        {heroData?.map((slider) => {
          return (
            <SwiperSlide className="clicent-slider" key={slider.id}>
              <SliderCard imgSrc={slider.logo_image} name={slider.name} review={slider.review} position={slider.position} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </CustomSlidBoxClient>
  );
}

export default ClientSlider;
