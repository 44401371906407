import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Grid12All } from "../../shared/GridWrap";
import { CustomFooter } from "../../../globalstyled/homeStyle";
import "./footer.scss";
import "../../../globalstyled/common/link.scss";
import { footerdata } from "../../../data";
import FooterCard from "./FooterCard";
import { getApiCall } from "../../../common/axios";
import { API_URL } from "../../../common/defines";

const Footer = () => {
  const [popularLink, setPopularLink] = useState(null);
  useEffect(() => {
    FooterPopular();
  }, []);
  const FooterPopular = async () => {
    try {
      const paramsData = {
        queryParams: {},
      };
      const res = await getApiCall(API_URL + "popular/getAllPopular", paramsData);
      if (res?.status) {
        setPopularLink(res?.data);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <Grid className="footer">
      <CustomFooter>
        <Grid container className={"footerWraper"} spacing={2}>
          {popularLink && <FooterCard title={"Popular in Outdoor Advertising"} linklist={popularLink} />}
          <FooterCard title={"Other Useful Links"} linklist={footerdata.usefullink} />
          <FooterCard title={"Marketing Purpose"} linklist={footerdata.purpose} />
          <FooterCard title={"Follow"} isdisabled={true} linklist={footerdata.follow} />
          <Grid12All>
            <p className="fottercopyright">© 2022 The Ishtihar. All icons and images © to their respective owners.</p>
          </Grid12All>
        </Grid>
      </CustomFooter>
    </Grid>
  );
};

export default Footer;
