import React from "react";
import { MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import { GridA } from "../../shared/GridWrap";

function FooterCard({ linklist, title, isLoad = false, isdisabled = false }) {
  const pushHandler = () => {
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };
  return (
    <GridA size={[12, 6, 3, 3]}>
      <p className="footer-title">{title}</p>
      {linklist?.map((link, k) => {
        return (
          <MenuItem className="footerlinklist" key={k}>
            {isLoad ? (
              <Link to={link.link} onClick={pushHandler} className="footer-link-list">
                {link.title}
              </Link>
            ) : (
              <Link
                to={link.link}
                className={`footer-link-list ${isdisabled && "disabled-css"} ${link.title.toLowerCase() === "mash" && "disabled-css"} ${
                  link.title.toLowerCase() === "ads" && "disabled-css"
                } ${link.title.toLowerCase() === "testimonials" && "disabled-css"} ${link.title.toLowerCase() === "our clients" && "disabled-css"}  `}
              >
                {link.title}
              </Link>
            )}
          </MenuItem>
        );
      })}
    </GridA>
  );
}

export default FooterCard;
