import * as yup from "yup";

export const initialValues = {
  name: "",
  email: "",
  message: "",
};
export const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup.string().required("Email is required").email("Email is invalid"),
  message: yup.string().required("Message is required"),
});
