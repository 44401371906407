
import * as yup from 'yup';
export const initialValues = {
  name: '',
  email: '',
  password: '',
  // mobilenumber: '',
};

export  const validationSchema = yup.object().shape({
  name: yup.string()
    .required('Name is required')
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
  email: yup.string()
    .required('Email is required')
    .email('Email is invalid'),
  password: yup.string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters')
    .max(40, 'Password must not exceed 40 characters'),
  // mobilenumber: yup.string()
  //   .required('Mobile Number is required'),
});

