import React, { useState } from "react";
import { TextField, Button } from "@mui/material";
import axios from "axios";
import Cookies from 'js-cookie'
import { API_URL } from "../../common/defines";
import { useDispatch } from "react-redux";
import LocalStorage from "../../services/local_storage";
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from "@mui/icons-material/Visibility";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
const UserPassword = ({ changeButtonHandler }) => {
  const localstorage = new LocalStorage();
  const dispatch = useDispatch();
  const [newPassword, setNewPassword] = useState({
    current: "",
    new: "",
  });

  const [showPassword , setShowPassword] = useState(false);
  const [showPassword2 , setShowPassword2] = useState(false);
  const clickIcon =()=>{
    setShowPassword(!showPassword)
  }
  const clickIcon2 =()=>{
    setShowPassword2(!showPassword2)
  }
  const updateButtonHandler = () => {
    if(newPassword.new.length >=6){
      setNewPassword((prevState) => {
        return { ...prevState, current: "", new: "" };
      });
      userProfileDetailApi();
    } else if(newPassword.current.length <= 6) {
      alert('Current Password must be at least 6 characters')
    } else if(newPassword.new.length <= 6){
      alert('New Password must be at least 6 characters')
    }
    
  };
  const currentPasswordHandler = (e) => {
    setNewPassword((prevState) => {
      return { ...prevState, current: e.target.value };
    });
  };
  const newPasswordHandler = (e) => {
    setNewPassword((prevState) => {
      return { ...prevState, new: e.target.value };
    });
  };

  const userProfileDetailApi = () => {
    const headers = {
      Authorization: localstorage.getItem("token"),
    };
    axios
      .get(
        `${API_URL}profile/profileDetail/${Cookies.get('userId')}`,
        { headers }
      )
      .then((response) => {
        passwordChangeApi({
          ...response.data.data,
          currentPassword: newPassword.current,
          userPassword: newPassword.new,
        });
        if (response.data.status === false) {
        } else if (response.data.status === true) {
        }
      })
      .catch((error) => {});
  };

  const passwordChangeApi = (option) => {
    const options = option;
    const headers = {
      Authorization: localstorage.getItem("token"),
    };
    axios
      .put(`${API_URL}profile/editProfile`, options, { headers })
      .then((response) => {
        if (response.data.status === false) {
          alert(response.data.message);
        } else if (response.data.status === true) {
          changeButtonHandler();
          dispatch({
            type: "notificationTrigger",
            payload: `Password Updated Successfully`,
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  return (
    <>
    <KeyboardBackspaceIcon onClick={changeButtonHandler} className="backarrowpassword" />
      <TextField
        id="current-password"
        label="Current Password"
        onChange={currentPasswordHandler}
        value={newPassword.current}
        type={showPassword ? "text" : "password"}
        variant="standard"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {!showPassword ===true ? <Visibility onClick={clickIcon} style={{cursor:"pointer"}} /> : <VisibilityOffIcon onClick={clickIcon} style={{cursor:"pointer"}} />}
              
            </InputAdornment>
          ),
        }}
      />
      <TextField
        id="new-password"
        label="New Password"
        onChange={newPasswordHandler}
        value={newPassword.new}
        type={showPassword2 ? "text" : "password"}
        variant="standard"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {!showPassword2 ===true ? <Visibility onClick={clickIcon2} style={{cursor:"pointer"}} /> : <VisibilityOffIcon onClick={clickIcon2} style={{cursor:"pointer"}} />}
              
            </InputAdornment>
          ),
        }}
      />
      <Button
        onClick={updateButtonHandler}
        className={`updatepasswordbutton" `}
        variant="contained"
      >
        Update
      </Button>
    </>
  );
};

export default UserPassword;
